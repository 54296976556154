import { axiosClient, getRequest } from "../../../services/axios/axiosClient";

export function getMapEventsList() {
  return getRequest("public_events/map")
    .then((res) => {
      return Promise.resolve(
        res.data.map((event) => {
          return {
            ...event,
            category: { display_name: event.category },
            comments_count: 0,
            id: event.place_id,
            location: {
              country: event.secondary_text,
              id: event.place_id,
              latitude: event.latitude,
              longitude: event.longitude,
              name: event.location,
            },
            name: event.location,
            rating: 0,
            view_link: event.url,
          };
        }),
      );
    })
    .catch((err) => Promise.reject(err));
}

export function getHelipaddySites() {
  return axiosClient
    .get("https://api.helipaddy.com/api/external/pads?region=EUR", {
      headers: {
        Authorization: "$2y$10$w5A90rfLTtywnt4pD.gl5OGiK",
      },
    })
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
}
