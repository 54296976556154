import React from "react";
import PropTypes from "prop-types";

const ConditionalRendering = ({ showComponent, children }) => {
  if (!showComponent) return "";
  return <React.Fragment>{children}</React.Fragment>;
};
ConditionalRendering.propTypes = {
  showComponent: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
    PropTypes.object,
  ]),
};
export default ConditionalRendering;
