import useWidgetConfigStore from "../../../../store/useWidgetConfigStore";

const useOnDemand = () => {
  const { onDemandDefaultLocation } = useWidgetConfigStore((state) => state.widgetConfig.locations) || {};

  return {
    onDemandDefaultLocation,
  };
};
export default useOnDemand;
