import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import AutoCompleteDropdownItem from "./AutoCompleteDropdownItem";
import { trimAll } from "../../../utils/stringUtils";

const locationsTypeLength = {
  city: 0,
  venues: 0,
  events: 0,
  helipads: 0,
};

const listKeys = [
  { key: "city" },
  { key: "venues", label: "ADDRESS OR VENUES" },
  { key: "helipads" },
  { key: "events" },
];

function AutoCompleteDropdown(props) {
  const {
    handleItemSelect,
    activeItem,
    locations,
    locations: { city, events, venues },
  } = props;

  useEffect(() => {
    locationsTypeLength.venues = city?.length;
    locationsTypeLength.events = locationsTypeLength.venues + Number(venues?.length);
    locationsTypeLength.helipads = locationsTypeLength.events + Number(events?.length);
  }, [locations]);

  useEffect(() => {
    document
      .querySelector(".gh-widget-autocomplete-list-item-active")
      ?.scrollIntoView({ block: "center", behavior: "smooth" });
  }, [activeItem]);

  const isCurrentItemActive = (index) => (index === activeItem ? "gh-widget-autocomplete-list-item-active" : "");
  //hide keyboard on mobile
  const handleTouchStart = () =>
    document.querySelectorAll(".gh-widget-input-type-autocomplete").forEach((el) => el.blur());

  return (
    <div
      className="gh-widget-autocomplete-dropdown"
      onTouchStart={handleTouchStart}
    >
      <div className="gh-widget-autocomplete-place-containers">
        <div className="gh-widget-autocomplete-place-container">
          {listKeys.map(
            ({ key, label }) =>
              locations[key]?.length > 0 && (
                <React.Fragment key={key}>
                  <h6 className="gh-widget-autocomplete-list-title">{label || key}</h6>
                  <ul className="gh-widget-autocomplete-list">
                    {locations[key]?.map((place, index) => (
                      <AutoCompleteDropdownItem
                        key={place.place_id}
                        place={place}
                        className={isCurrentItemActive(index + locationsTypeLength[key])}
                        onClick={(event) => handleItemSelect(index + locationsTypeLength[key], event)}
                        locationType={trimAll(key + label)}
                      />
                    ))}
                  </ul>
                </React.Fragment>
              ),
          )}
        </div>
      </div>
    </div>
  );
}

AutoCompleteDropdown.propTypes = {
  locations: PropTypes.shape({
    city: PropTypes.array,
    events: PropTypes.array,
    helipads: PropTypes.array,
    venues: PropTypes.array,
  }),
  activeItem: PropTypes.number,
  handleItemSelect: PropTypes.func.isRequired,
};
AutoCompleteDropdown.defaultProps = { locations: {} };

export default memo(AutoCompleteDropdown);
// export default AutoCompleteDropdown;
