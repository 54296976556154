import PropTypes from "prop-types";
import React from "react";
import DefaultMarker from "./DefaultMarker";
import GoogleMarker from "./GoogleMarker";
import EventMarker from "./EventMarker";
import MarkerInfoContext from "./MarkerInfoContext";

import "./markerInfo.css";

export default function MarkerInfo(props) {
  const { pin, isSelected, actions, updateChosenSitesByRole } = props;

  // eslint-disable-next-line react/no-multi-comp
  function Content() {
    if (pin) {
      if (pin.source === "google") return <GoogleMarker />;
      if (pin.source === "gh_event") return <EventMarker />;
      return <DefaultMarker />;
    }

    return null;
  }

  return (
    <MarkerInfoContext.Provider value={{ pin, isSelected, actions, updateChosenSitesByRole }}>
      {Content()}
    </MarkerInfoContext.Provider>
  );
}

MarkerInfo.propTypes = {
  pin: PropTypes.shape({
    name: PropTypes.string,
    comments_count: PropTypes.number,
    rating: PropTypes.number,
    image: PropTypes.string,
    category: PropTypes.string,
    source: PropTypes.string,
    type: PropTypes.string,
    handleAssignButton: PropTypes.func,
    view_link: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  actions: PropTypes.bool,
  updateChosenSitesByRole: PropTypes.bool,
};

MarkerInfo.defaultProps = {};
