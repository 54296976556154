export const loadWidgetScript = () => {
  try {
    const e = document.createElement("link");
    e.setAttribute("rel", "stylesheet");
    e.setAttribute("href", `${process.env.REACT_APP_WEBSITE_URL}/static/css/main.css`);
    document.getElementsByTagName("head")[0].appendChild(e);
  } catch (e) {
    // console.error("Widget connecting error. Please contact GetHeli Support");
  }
};

export const loadIframeScript = (renderDOM, obs = null) => {
  const element = document.getElementById("getheli-iframe-base-element");
  const hasElementChildren = !!element?.children?.length;
  if (!element || hasElementChildren) return;
  renderDOM();
  if (!obs) return;
  window.addEventListener("beforeunload", function () {
    obs.disconnect();
  });
};

export const getWidgetToken = () => {
  const loadingScript = document.querySelector("[data-gh-wg-token]");
  const widgetToken = loadingScript?.getAttribute("data-gh-wg-token");
  // if (!widgetToken) console.error("Widget connecting error. Please contact GetHeli Support");
  return widgetToken;
};
