import React from "react";
import PropTypes from "prop-types";
import Button from "../../../UI/Button";
import CountDown from "../../../UI/CountDown";
import { startAgainLabel, thankYou } from "../../../../constants/content";

function ExternalEnquiryFormSuccess(props) {
  const { handleResetForm, show } = props;
  if (!show) return "";
  return (
    <div className="gh-widget-external-enquiry-form_wrapper">
      <div className="gh-widget-external-enquiry-status">
        <div className="gh-widget-margin-top-25">
          <h3 className="gh-widget-content__title">{thankYou}</h3>
          <p className="gh-widget-content__descrp">We will be in touch with you shortly.</p>
        </div>
        <div className="gh-widget-d-flex gh-widget-flex-column gh-widget-flex-align-center gh-widget-margin-top-15">
          <Button onClick={handleResetForm}>
            {startAgainLabel} (
            <CountDown
              isCountDownStarted={show}
              onFinish={handleResetForm}
            />
            )
          </Button>
        </div>
      </div>
    </div>
  );
}

ExternalEnquiryFormSuccess.propTypes = {
  handleResetForm: PropTypes.func,
  show: PropTypes.bool,
};
export default ExternalEnquiryFormSuccess;
