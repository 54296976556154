export const generateSecondaryText = (address) => {
  const country = address.country ? (address.country === "GB" ? "UK" : address.country) : "";
  return [address.county, country].filter(Boolean).join(", ");
};

/**
 * @name generateLocationAdditionalInfo
 *
 * @param {object} address
 * @param {number} address.latitude
 * @param {string} address.type
 * @param {string} address.category
 * @param {string} address.county
 * @param {string} address.country
 * @param {string} address.secondary_text
 * @param {string} address.source
 * @param {string} address.name
 *
 * @returns {string} Location additional info
 */
export default (address) => {
  const latitude = address.lat || address.latitude;

  const getSecondaryText = () => {
    if (address.secondary_text) {
      return `${address.secondary_text || ""}`;
    }

    return generateSecondaryText(address);
  };

  if (latitude) {
    return `${getSecondaryText(address)}`;
  }

  return "";
};
