import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect } from "react";
import FlyDirectionMarker from "./FlyDirectionMarker";
import FlyDirectionLine from "./FlyDirectionLine";
import { LondonLocation, primaryIcon } from "../Map.context";
import { getLandingSitesMarkers, parseCoordinates } from "../Map.utils";
import usePrevious from "../../../../hooks/usePrevious";

let bounds;

const getIconName = (location) => {
  if (location.source === "gh_event" && location.category) {
    return location.category;
  } else {
    return location.type || location.category;
  }
};

function FlyDirection(props) {
  const { flyCoordinates, projection, google, map, zoom, setActiveInfo, closeActiveInfo, sites } = props;
  const prevCoords = usePrevious();

  useEffect(() => {
    if (google) {
      if (JSON.stringify(flyCoordinates) !== JSON.stringify(prevCoords)) {
        closeActiveInfo();

        bounds = new google.maps.LatLngBounds();

        flyCoordinates.forEach(({ from, to }) => {
          if (from) bounds.extend(parseCoordinates(from));
          if (to) bounds.extend(parseCoordinates(to));
        });

        if (!flyCoordinates || !flyCoordinates.length) {
          map.panTo(LondonLocation);
          map.setZoom(6);
          return;
        }

        if (flyCoordinates.every((pos) => !!pos.to && !!pos.from)) {
          map.fitBounds(bounds, 100);
          return;
        }

        const from = parseCoordinates(flyCoordinates[0].from);
        const to = parseCoordinates(flyCoordinates[0].to);

        map.panTo(from || to || LondonLocation);
        map.setZoom(10);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flyCoordinates]);

  const mouseOverHandler = useCallback(
    (marker = null, pad = null) =>
      () => {
        if (marker) {
          setActiveInfo({
            visible: true,
            pad,
            marker,
            isSelected: true,
          });
        }
      },
    [setActiveInfo],
  );

  if (!google || !sites) return null;

  return flyCoordinates.map((marker, idx) => {
    const info = getLandingSitesMarkers(marker.pad, sites);

    if (!marker.to && !marker.from) return;

    return (
      <div key={marker.key || idx}>
        {marker.from && (
          <FlyDirectionMarker
            position={parseCoordinates(marker.from) || null}
            map={map}
            google={google}
            info={{
              ...info.from,
              chosenSiteText: marker.to === marker.from ? "This is your chosen take-off/landing site" : "",
            }}
            mouseOverHandler={mouseOverHandler}
            icon={primaryIcon(getIconName(info.from))}
            hide={marker.key === "map-location-copy"}
          />
        )}

        <FlyDirectionLine
          google={google}
          map={map}
          projection={projection}
          range={{ to: marker.to, from: marker.from }}
          zoom={zoom}
        />

        {marker.to && (
          <FlyDirectionMarker
            position={parseCoordinates(marker.to) || null}
            map={map}
            google={google}
            info={{
              ...info.to,
              chosenSiteText: marker.to === marker.from ? "This is your chosen take-off/landing site" : "",
            }}
            mouseOverHandler={mouseOverHandler}
            icon={primaryIcon(getIconName(info.to))}
            hide={marker.key === "map-location-copy"}
          />
        )}
      </div>
    );
  });
}

FlyDirection.propTypes = {
  flyCoordinates: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      pad: PropTypes.object,
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
  setActiveInfo: PropTypes.func,
  closeActiveInfo: PropTypes.func,
  projection: PropTypes.object,
  google: PropTypes.object,
  map: PropTypes.object,
  zoom: PropTypes.number,
  sites: PropTypes.array,
};

FlyDirection.defaultProps = {
  flyCoordinates: [],
};

export default memo(FlyDirection);
