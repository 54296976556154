/**
 * @param {String} groupName - ex: "passengers"
 * @param {Function} getValues - React Hook Form function, ex: getValues("groupName")
 * @param {number} [atLeastAmount=1] - ex: 2
 * @param {string} [customErrorMessage="At least one passenger is required"] - ex: "Please select at least 2 options"
 * */
export const validateArrayAtLeastN =
  (groupName, getValues, atLeastAmount = 1, customErrorMessage) =>
  () => {
    const errorMessage = customErrorMessage || "At least one passenger is required";
    const result =
      Object.values(getValues(groupName)).some((fieldValue) => fieldValue > atLeastAmount - 1) || errorMessage;
    return result;
  };
