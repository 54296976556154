import React from "react";
import "./ButtonEnquiry.scss";
import PropTypes from "prop-types";
import { useWidgetConfig } from "../../../context/widgetConfigContext";

function ButtonEnquiry(props) {
  const { onClick } = props;
  const { dynamicContent } = useWidgetConfig();
  const label = dynamicContent?.widget_button_label || "Enquiry";

  return (
    <div className="gh-widget-button-enquiry-wrapper">
      <button
        className="gh-widget-button-enquiry pulse-animation"
        type="button"
        onClick={onClick}
      >
        {label}
      </button>
    </div>
  );
}

ButtonEnquiry.propTypes = {
  onClick: PropTypes.func,
};

ButtonEnquiry.defaultProps = {};

export default ButtonEnquiry;
