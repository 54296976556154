/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useUnmount } from "react-use";
import isMobile from "../../../../utils/isMobile";

export default function FlyDirectionMarker(props) {
  const { position, google, map, icon, info, mouseOverHandler } = props;
  const [prevIcon, setPrevIcon] = useState(icon);
  const marker = useRef(
    new google.maps.Marker({
      map,
      icon,
      visible: !props.hide,
      zIndex: 100,
    }),
  );

  const updateMouseOverEvent = () => {
    if (isMobile()) {
      marker.current.addListener("click", mouseOverHandler(marker.current, info));
    } else {
      marker.current.addListener("mouseover", mouseOverHandler(marker.current, info));
    }
  };

  const updateMarkerIcon = () => {
    marker.current.setIcon(icon);
  };
  const updateMarker = () => {
    if (position) {
      marker.current.setOptions({
        position,
        opacity: 1.0,
      });
    } else {
      marker.current.setOptions({
        opacity: 0.0,
      });
    }
  };

  useEffect(() => {
    if (marker.current) {
      updateMarker();
      updateMouseOverEvent();
    }
  }, []);

  useEffect(() => {
    updateMarker();
    updateMouseOverEvent();
  }, [position]);

  useEffect(() => {
    if (JSON.stringify(icon) !== JSON.stringify(prevIcon)) {
      updateMarkerIcon();
    }

    setPrevIcon(icon);
  }, [icon]);

  useUnmount(() => {
    marker.current.setMap(null);
  });

  return null;
}

FlyDirectionMarker.propTypes = {
  google: PropTypes.object,
  map: PropTypes.object,
  position: PropTypes.object,
  info: PropTypes.object,
  mouseOverHandler: PropTypes.func,
  icon: PropTypes.string,
  hide: PropTypes.bool,
};
