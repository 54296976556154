import PropTypes from "prop-types";
import { Map as GoogleMap } from "google-maps-react";
import React from "react";
import MarkerCluster from "./MarkerCluster";
import FlyDirection from "./FlyDirection";
import CustomMarker from "./CustomMarker";
import { LondonLocation, mapStyles } from "./Map.context";
import { axiosClient, getRequest } from "../../../services/axios/axiosClient";

import "./Map.styles.scss";
import { toast } from "react-toastify";

export default function Map(props) {
  const { pads, flyCoordinates, assignAction, allowCustomMarker, sites } = props;
  const [mapState, setMapState] = React.useState({ projection: null, zoom: null });
  const [activeMarker, setActiveMarker] = React.useState({
    marker: null,
    visible: false,
    pad: null,
    isSelected: false,
  });
  const [customPin, setCustomPin] = React.useState({});
  const [isMapReady, setIsMapReady] = React.useState(false);

  const closeMarkerInfo = React.useCallback(() => {
    setActiveMarker({
      visible: false,
      marker: null,
      pad: null,
      isSelected: false,
    });
  }, []);

  const mapId = React.useRef(Math.random().toString(16).substring(7));

  const onZoomChanged = React.useCallback((_, _map) => setMapState((prev) => ({ ...prev, zoom: _map.getZoom() })), []);

  const onProjectionChanged = React.useCallback(
    (_, _map) => setMapState((prev) => ({ ...prev, projection: _map.getProjection() })),
    [],
  );

  const onReadyHandler = (_, _map) => {
    onZoomChanged(null, _map);
    setIsMapReady(true);
  };

  const mapClickHandler = async (_, __, pos) => {
    if (activeMarker.visible || customPin.place_id) {
      closeMarkerInfo();
      setCustomPin({});
    } else if (allowCustomMarker) {
      const coords = { lat: pos.latLng.lat(), lng: pos.latLng.lng() };

      axiosClient.defaults.skipDefaultToast = true;

      try {
        const response = await getRequest("reverse-geocode", { latitude: coords.lat, longitude: coords.lng });

        if (response) {
          setCustomPin(response.data);
        }
      } catch (error) {
        toast.error("This country is not available.");
      }
    }
  };

  return (
    <GoogleMap
      minZoom={4}
      zoom={10}
      clickableIcons
      initialCenter={LondonLocation}
      google={window.google}
      onProjectionChanged={onProjectionChanged}
      onZoomChanged={onZoomChanged}
      onReady={onReadyHandler}
      onClick={mapClickHandler}
      styles={mapStyles}
      containerStyle={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {isMapReady && (
        <MarkerCluster
          map={mapState.projection}
          google={window.google}
          pads={pads}
          setActiveMarker={setActiveMarker}
          activeMarker={activeMarker}
          mapId={mapId.current}
          closeMarkerInfo={closeMarkerInfo}
        />
      )}

      {allowCustomMarker && (
        <CustomMarker
          markerInfo={customPin}
          setActiveMarker={setActiveMarker}
          assignAction={assignAction}
        />
      )}

      {!!mapState.projection && !!mapState.zoom && sites && (
        <FlyDirection
          flyCoordinates={flyCoordinates}
          projection={mapState.projection}
          zoom={mapState.zoom}
          setActiveInfo={setActiveMarker}
          closeActiveInfo={closeMarkerInfo}
          sites={sites}
        />
      )}
    </GoogleMap>
  );
}

Map.propTypes = {
  pads: PropTypes.array,
  assignAction: PropTypes.func,
  flyCoordinates: PropTypes.array,
  allowCustomMarker: PropTypes.bool,
  sites: PropTypes.array,
};
