import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./HelicoptersOptionsList.scss";
import HelicoptersOptionsItem from "../HelicoptersOptionsItem";
import { optionsPriceDependsOneWay, optionsPriceDependsReturn } from "../../../constants/content";
import { useWidgetConfig } from "../../../context/widgetConfigContext";
import ConditionalRendering from "../../UI/ConditionalRendering";
import { ONE_WAY } from "../../../constants/otherConstants";

export default function HelicoptersOptionsList({ helicoptersList, typeOfTrip, handleGoToInfo, warningMessages }) {
  const { setGlobalState } = useWidgetConfig();

  const tooltipDescription = typeOfTrip === ONE_WAY ? optionsPriceDependsOneWay : optionsPriceDependsReturn;

  useEffect(() => {
    if (!helicoptersList?.length) return;
    // setting tooltip data in global context
    const data = [];
    helicoptersList.forEach(({ id, model, description, tooltip_message }, index) => {
      const helicopterId = id + model.toString().replaceAll(/\s/g, "") + index;

      // title option's tooltip
      data.push({
        id: `heli-title-${helicopterId}`,
        description: tooltip_message,
      });

      // first option's tooltip
      data.push({
        id: `tooltip-${helicopterId}`,
        description,
      });
      // second option's tooltip
      data.push({
        id: `heli-description-${helicopterId}`,
        description: tooltipDescription,
      });
    });

    setGlobalState((prev) => ({
      ...prev,
      tooltips: {
        ...prev.tooltips,
        helicoptersList: data,
      },
    }));
  }, [helicoptersList, setGlobalState]);

  return (
    <div className="gh-widget-helicopters-list gh-widget-margin-bottom-15">
      <ConditionalRendering showComponent={helicoptersList && helicoptersList.length === 0}>
        <div className="gh-widget-gray-warning">
          <span className=" gh-widget-danger-opostrov gh-widget-vertical-middle" />
          <span>
            There are no aircraft that meet your search criteria. Please either submit a new enquiry or get in contact
            with the Get Heli team.
          </span>
        </div>
      </ConditionalRendering>
      <div className="gh-widget-helicopters-list-content">
        {helicoptersList &&
          helicoptersList.map((item, index) => {
            const { estimated_price, id, model, description, images, max_passengers, duration } = item;
            const helicopterId = id + model.toString().replaceAll(/\s/g, "") + index;
            return (
              <React.Fragment key={id}>
                <HelicoptersOptionsItem
                  estimated_price={estimated_price}
                  helicopterId={helicopterId}
                  model={model}
                  description={description}
                  duration={duration}
                  images={images}
                  max_passengers={max_passengers}
                  id={item.id}
                  handleGoToInfo={handleGoToInfo}
                  warningMessages={warningMessages}
                  warningMessage={item.warning_message}
                />
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
}

HelicoptersOptionsList.propTypes = {
  helicoptersList: PropTypes.array,
  typeOfTrip: PropTypes.string,
  handleGoToInfo: PropTypes.func,
  warningMessages: PropTypes.object,
};
