import React from "react";
import PropTypes from "prop-types";
import "./ErrorMessageComponent.scss";
import { CSSTransition } from "react-transition-group";
import cs from "classnames";

function ErrorMessageComponent(props) {
  const { children, className } = props;

  return (
    <CSSTransition
      in={!!children}
      timeout={300}
      classNames={{
        // exit: "gh-widget-autocomplete-focus-background-hide",
        enterActive: "gh-widget-validation-errors-enter-active",
        exitActive: "gh-widget-autocomplete-animation-exit-active",
      }}
      // unmountOnExit
    >
      <div
        className={cs("gh-widget-validation-errors disabled-list gh-widget-input-errors", className, {
          "gh-widget-app-disabled-error": !children,
        })}
      >
        {children || ""}
      </div>
    </CSSTransition>
  );
}

ErrorMessageComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
};

ErrorMessageComponent.defaultProps = {
  className: "",
};

export default ErrorMessageComponent;
