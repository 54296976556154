import React from "react";
import "./PoweredByGetHeli.scss";
import cs from "classnames";
import { ReactComponent as PoweredByGetHeliSVG } from "../../../../assets/svg/poweredByGetHeli.svg";
import { ReactComponent as PoweredByGetHeliTextSVG } from "../../../../assets/svg/poweredByGetHeliText.svg";
import PropTypes from "prop-types";

function PoweredByGetHeli({ show, className = "" }) {
  if (!show) return "";
  return (
    <a
      className={cs("gh-widget-powered-by-getheli-wrapper", className)}
      role="link"
      href="https://getheli.com/"
      target="_blank"
      rel="noreferrer"
    >
      <div className="gh-widget-margin-right-10">
        <PoweredByGetHeliTextSVG />
      </div>
      <PoweredByGetHeliSVG />
    </a>
  );
}

PoweredByGetHeli.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
};
PoweredByGetHeli.defaultProps = {
  className: "",
  show: true,
};
export default PoweredByGetHeli;
