import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./AutoComplete.scss";
import { CSSTransition } from "react-transition-group";
import Input from "../Input";
import { getRequest } from "../../../services/axios/axiosClient";
import AutoCompleteDropdown from "./AutoCompleteDropdown";
import { isObjectEmpty } from "../../../utils/objectUtils";
import Loader from "../Loader";
import { getTextWidth } from "../../../utils/stringUtils";
import AutocompleteReadOnly from "./AutocompleteReadOnly";

let numberOfPlaces = 0;
let forceStopAutocompleteRequest = false;
let inputWidth = 0;

function AutoComplete(props) {
  const {
    className,
    onChange,
    value,
    placeholder,
    label,
    forceSecondaryText,
    forceStopAutocomplete: forceStopAutocompleteProps,
    readOnlyData,
  } = props;
  const [locations, setLocations] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const [selectedPlaceInfo, setSelectedPlaceInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const [secondaryTextWidth, setSecondaryTextWidth] = useState(0);

  const locationsArray = useMemo(
    () =>
      // eslint-disable-next-line default-param-last
      Object.values(locations).length > 0 && Object.values(locations).reduce((acc = [], places) => [...acc, ...places]),
    [locations],
  );
  const getPlaces = () => {
    if (!value || value.length < 3 || forceStopAutocompleteRequest || forceStopAutocompleteProps || readOnlyData?.dir) {
      setIsLoading(false);
      return;
    }
    getRequest("get-locations", { keyword: value })
      .then((res) => {
        const { data } = res;
        if (data && !isObjectEmpty(data)) {
          // count number of places
          numberOfPlaces = Object.values(data).reduce((acc = 0, place) =>
            typeof acc === "object" ? acc.length + place.length : acc + place.length,
          );
          setLocations(data);
          setShowDropdown(true);
        } else setShowDropdown(false);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setShowDropdown(false);
        setLocations({});
      });
  };

  // clear secondary text when form is restarted or dont have any value and secondary text
  useEffect(() => {
    if (!value && !forceSecondaryText) setSelectedPlaceInfo("");
  }, [value, forceSecondaryText]);

  useEffect(() => {
    if (!value || value.length < 3 || forceStopAutocompleteRequest || forceStopAutocompleteProps) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    const debounce = setTimeout(() => {
      getPlaces();
    }, 250);

    setSelectedPlaceInfo("");
    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(debounce);
    };
  }, [value]);

  const handleCloseDropdown = (event) => {
    if (!event.target.classList.contains("gh-widget-autocomplete-focus-background")) return;
    setShowDropdown(false);
    setActiveItem(0);
  };
  const handleItemSelect = (index) => {
    const { location, category, secondary_text } = locationsArray[index];
    onChange(location, locationsArray[index]);
    setSelectedPlaceInfo(`${category} • ${secondary_text}`);
    setShowDropdown(false);
    setActiveItem(0);
    forceStopAutocompleteRequest = true;
  };

  const handleInputFocus = (isInputFocused) => {
    if (!isInputFocused) return;
    forceStopAutocompleteRequest = false;
    // setShowDropdown(true);
  };

  const handleKeyDown = (event) => {
    // User pressed the enter key
    if (event.keyCode === 13) {
      event.preventDefault();
      if (!showDropdown) return getPlaces();
      handleItemSelect(activeItem);
      event.target.blur();
      // setSelectedPlaceInfo(0);
    }
    // User pressed the up arrow
    else if (event.keyCode === 38) {
      event.preventDefault();
      if (activeItem) setActiveItem(activeItem - 1);
    }
    // User pressed the down arrow
    else if (event.keyCode === 40) {
      event.preventDefault();
      if (activeItem + 1 < numberOfPlaces) setActiveItem(activeItem + 1);
    }
  };

  useEffect(() => {
    inputWidth = Number(inputRef.current?.clientWidth) - 25;
  }, [inputRef]);

  useEffect(() => {
    const textWidth = getTextWidth(value);
    if (inputWidth / 2 < textWidth && inputWidth - textWidth > 0) setSecondaryTextWidth(inputWidth - textWidth);
    else setSecondaryTextWidth(0);
  }, [value, inputRef.current]);

  if (readOnlyData)
    return (
      <AutocompleteReadOnly
        readOnlyData={readOnlyData}
        label={label}
      />
    );

  return (
    <div
      className={`${className} gh-widget-autocomplete-wrapper`}
      onClick={handleCloseDropdown}
      role="button"
    >
      <p className="gh-widget-label">{label}</p>
      <div
        className="gh-widget-autocomplete-input-wrapper"
        ref={inputRef}
      >
        <Input
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          className={`gh-widget-old-input gh-widget-autocomplete-input gh-widget-input-type-autocomplete ${
            selectedPlaceInfo || forceSecondaryText ? "" : "gh-widget-autocomplete-input-full-width"
          }`}
          autoComplete="disable-autocomplete"
        />
        <div
          className="gh-widget-autocomplete-loader-secondary-text-wrapper"
          style={{
            width: secondaryTextWidth || "45%",
            display: selectedPlaceInfo || forceSecondaryText ? "flex" : "none",
            justifyContent: "flex-end",
          }}
        >
          <div className="gh-widget-autocomplete-input-additional-info">{forceSecondaryText || selectedPlaceInfo}</div>
        </div>
        <Loader
          loading={isLoading}
          className="gh-widget-autocomplete-spinner"
        />
      </div>
      <CSSTransition
        in={showDropdown && !!numberOfPlaces}
        timeout={300}
        classNames={{
          exit: "gh-widget-autocomplete-focus-background-hide",
          enterActive: "gh-widget-autocomplete-animation-enter-active",
          exitActive: "gh-widget-autocomplete-animation-exit-active",
        }}
        unmountOnExit
      >
        <AutoCompleteDropdown
          activeItem={activeItem}
          locations={locations}
          handleItemSelect={handleItemSelect}
        />
      </CSSTransition>
      <div
        className={
          showDropdown ? "gh-widget-autocomplete-focus-background" : "gh-widget-autocomplete-focus-background-hide"
        }
      />
    </div>
  );
}

AutoComplete.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  forceSecondaryText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  forceStopAutocomplete: PropTypes.bool,
  readOnlyData: PropTypes.object,
};
AutoComplete.defaultProps = {
  className: "",
  value: "",
  forceSecondaryText: "",
  forceStopAutocomplete: false,
  readOnlyData: null,
};
export default AutoComplete;
