import React from "react";
import PropTypes from "prop-types";
import "./EventContent.scss";
import Title from "../../../UI/Title";
import LineSeparator from "../../../UI/LineSeparator";
import { datesLabel, eventTypeLabel, tooltipFeatured, venueLabel } from "../../../../constants/content";
import EventContentInfoRow from "../EventContentInfoRow";
import EventsContentDynamic from "../EventsContentDynamic";
import Text from "../../../UI/Text";
import Link from "../../../UI/Link";
import TwoColumnsWithForm from "../../../layouts/WindowLayouts/TwoColumnsWithForm";
import eventThumbnailPlaceholder from "../../../../assets/img/eventThumbnailMin.png";
import cs from "classnames";
import ButtonBack from "../../../UI/ButtonBack";
import Tag from "../../../common/Tags/Tag";
import { ReactComponent as GetheliSmallLogo } from "../../../../assets/svg/getheliSmallLogo.svg";
import { ReactComponent as StartSVG } from "../../../../assets/svg/star.svg";
import useProgressiveImg from "../../../../hooks/useProgressiveImg";
import useHasFeature from "../../../../hooks/useHasFeature";
import { lettersOnly } from "../../../../utils/stringUtils";
import { EVENT_FROM } from "../../../../constants/otherConstants";
import { POWERED_BY_GETHELI } from "../../../../constants/features";

const imageTagLabel = "Event";
const EventContent = (props) => {
  const { eventDetails, handleModalClose } = props;
  const { name, town, country, dates, venue, venue_url, type, start_date, end_date, id: eventID } = eventDetails;
  const { hasFeature } = useHasFeature(POWERED_BY_GETHELI);

  const location = React.useMemo(() => `${town}, ${country}`, [town, country]);
  const eventImage = eventDetails?.photo;
  const eventLocation = {
    ...eventDetails?.event_location,
    location: eventDetails?.event_location.name || eventDetails?.name,
    category: eventDetails?.event_location.type,
  };
  const isFormDisabled = !eventDetails?.event_location.name;
  const isEventFeatured = React.useMemo(() => !!eventDetails?.is_featured_event, [eventDetails]);
  const tooltipId = React.useMemo(() => lettersOnly(`${name}_${start_date}_${end_date}`), [name, end_date, start_date]);

  const lowQualityImage = eventImage && eventImage + "?width=10";
  const highQualityImage = eventImage && eventImage + "?width=435";
  const [src, { blur }] = useProgressiveImg(lowQualityImage, highQualityImage, eventThumbnailPlaceholder);
  const eventImageProcessed = React.useMemo(() => ({ src, blur }), [blur, src]);

  // when open an event scroll to top of widget
  React.useEffect(() => {
    const widgetWrapper = document.querySelector(".gh-widget-main-wrapper");
    if (!widgetWrapper) return;
    widgetWrapper.scrollIntoView({ block: "start" });
  }, []);

  const externalEnquiryFormDefaultLocation = React.useMemo(
    () => ({
      dir: "to",
      location: eventLocation,
    }),
    [eventLocation],
  );

  return (
    <div className={cs("gh-widget-event-content-container")}>
      <ButtonBack
        onClick={handleModalClose}
        label="Back to All Events"
        className="gh-widget-event-back-btn"
      />
      <div className="gh-widget-content-layout-wrapper">
        {/* todo: wrap in a component */}
        <div
          className={cs(
            "gh-widget-column-layout-event-image-wrapper",
            "gh-widget-column-layout-event-image-wrapper-changed-place",
          )}
        >
          <img
            src={src}
            alt="Event"
            className={cs("gh-widget-column-layout-event-image")}
            style={{
              filter: blur ? "blur(10px)" : "none",
              transition: blur ? "none" : "filter .4s ease-out",
            }}
          />

          <Tag
            Icon={StartSVG}
            className="gh-widget-card-featured-tag gh-widget-card-featured-tag-inside-event"
            show={isEventFeatured}
            tooltipId={tooltipId}
            tooltipContent={tooltipFeatured}
          />
        </div>
        {/*======================*/}
        <TwoColumnsWithForm
          eventThumbnail={eventImageProcessed}
          externalEnquiryFormDefaultLocation={externalEnquiryFormDefaultLocation}
          imageTagLabel={imageTagLabel}
          formTitle="Enquiry Form"
          ifFormDisabled={isFormDisabled}
          isEventFeatured={isEventFeatured}
          tooltipId={tooltipId}
          formType={EVENT_FROM}
          isMapUsed={false}
          eventID={eventID}
        >
          <div className="gh-widget-event-content-wrapper">
            <Title
              content={name}
              type="standard"
              className="gh-widget-events-title-main"
            />
            <Title
              content={location}
              type="medium"
              className="gh-widget-events-title-location"
            />
            <EventContentInfoRow
              title={datesLabel}
              value={dates}
            />
            <EventContentInfoRowVenueLink
              venue={venue}
              venue_url={venue_url}
            />
            <EventContentInfoRow
              title={eventTypeLabel}
              value={type}
            />
            <LineSeparator className="gh-widget-margin-bottom-25" />
            <EventsContentDynamic eventDetails={eventDetails} />
          </div>
        </TwoColumnsWithForm>
      </div>
    </div>
  );
};

EventContent.propTypes = { eventDetails: PropTypes.object, handleModalClose: PropTypes.func };
EventContent.defaultProps = {};
export default React.memo(EventContent);

// eslint-disable-next-line react/prop-types
const EventContentInfoRowVenueLink = ({ venue_url, venue }) => {
  return venue_url ? (
    <div className="gh-widget-events-info-rows">
      <Text className="gh-widget-events-info-element">{venueLabel}</Text>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        className="gh-widget-events-info-element"
        href={venue_url}
      >
        {venue}
      </Link>
    </div>
  ) : (
    <EventContentInfoRow
      title={venueLabel}
      value={venue}
    />
  );
};
