export const breakpoints = {
  lg: 1280,
  md: 1024,
  sm: 768,
  xs: 480,
  xxs: 0,
};

export const qc = (className, breakpoint) => `${className}-qc-${breakpoint}`;

export const queryBreakpoints = {
  "gh-widget-container-query-max-375": {
    maxWidth: 375,
  },
  "gh-widget-container-query-max-479": {
    maxWidth: 479,
  },
  "gh-widget-container-query-max-540": {
    maxWidth: 539,
  },
  "gh-widget-container-query-max-767": {
    maxWidth: 767,
  },
  "gh-widget-container-query-max-900": {
    maxWidth: 900,
  },
  "gh-widget-container-query-max-1040": {
    maxWidth: 1040,
  },
};
