import { geocodeByPlaceId } from "react-places-autocomplete";

const googleAPI = class GoogleApiService {
  constructor() {}

  getCoordinatesByPlaceId(placeId) {
    return new Promise((resolve, reject) => {
      geocodeByPlaceId(placeId)
        .then((results) => {
          let [address] = results;
          if (address && address.hasOwnProperty("geometry") && address.geometry.hasOwnProperty("location")) {
            return resolve({
              latitude: address.geometry.location.lat(),
              longitude: address.geometry.location.lng(),
            });
          }
          throw new Error("Could not get location");
        })
        .catch((error) => reject("Error", error));
    });
  }
};

export default new googleAPI();
