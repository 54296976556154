import React from "react";
import PropTypes from "prop-types";
import "./Card.scss";
import cs from "classnames";
import Title from "../Title";
import eventThumbnail from "../../../assets/img/eventThumbnailMin.png";
import useProgressiveImg from "../../../hooks/useProgressiveImg";
import { ReactComponent as StartSVG } from "../../../assets/svg/star.svg";
import Tag from "../../common/Tags/Tag";
import { dateCompactFormat } from "../../../utils/timeUtils";
import { lettersOnly } from "../../../utils/stringUtils";

const Card = ({ title, image, type, location, onClick, isFeaturedEvent, eventDetails }) => {
  const { start_date, end_date, start_month, end_month, short_description, id } = eventDetails;

  const lowQualityImage = image + "?width=5";
  const highQualityImage = image && image + "?width=450";
  const [src, { blur }] = useProgressiveImg(lowQualityImage, highQualityImage, eventThumbnail);

  let dates = "";
  if (start_date && end_date) {
    dates = `${dateCompactFormat(start_date)} - ${dateCompactFormat(end_date)}`;
  } else if (start_month) {
    dates = `${start_month} ${end_month ? "to " + end_month : ""}`;
  }

  const tooltipId = React.useMemo(() => lettersOnly(`${title}_`) + id, [title, end_date, start_date]);

  const handleOnClick = React.useCallback(() => (typeof onClick === "function" ? onClick() : null), [onClick]);
  const handleStopPropagation = React.useCallback((e) => e.stopPropagation(), []);

  return (
    <div
      className={cs("gh-widget-card", typeof onClick === "function" ? "gh-widget-pointer" : "")}
      onClick={handleOnClick}
      role="button"
    >
      <div className="gh-widget-card-image-wrapper">
        <img
          src={src}
          alt="event cover"
          className="gh-widget-card-image"
          style={{
            filter: blur ? "blur(10px)" : "none",
            transition: blur ? "none" : "filter 0.3s ease-out",
          }}
        />
        <Tag
          Icon={StartSVG}
          className="gh-widget-card-featured-tag"
          show={!!isFeaturedEvent}
          tooltipContent={isFeaturedEvent ? isFeaturedEvent : ""}
          tooltipId={tooltipId}
          onClick={handleStopPropagation}
        />
      </div>
      <div className="gh-widget-card-info">
        <Title
          content={title}
          type="small"
          className="gh-widget-margin-bottom-5"
        />
        <span className="gh-widget-card-additional-type-value gh-widget-card-additional-dates">
          {short_description || ""}
        </span>
        <span className="gh-widget-card-additional-type-value gh-widget-card-additional-dates">{dates}</span>
        <div className="gh-widget-card-additional">
          <div className="gh-widget-card-additional-left">
            <span className="gh-widget-margin-bottom-5 gh-widget-card-additional-type">Type</span>
            <span className="gh-widget-font-weight-600 gh-widget-card-additional-type-value">{type}</span>
          </div>
          <div className="gh-widget-card-additional-right">
            <span className="gh-widget-margin-bottom-5 gh-widget-card-additional-type">Location</span>
            <span className="gh-widget-font-weight-600 gh-widget-card-additional-type-value">{location}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
  location: PropTypes.string,
  onClick: PropTypes.func,
  isFeaturedEvent: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  eventDetails: PropTypes.object,
};
Card.defaultProps = {
  onClick: null,
  dates: "",
  isFeaturedEvent: false,
};
export default React.memo(Card);
