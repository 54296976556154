export const brokersHotFixes = () => {
  try {
    const elementPrimary = document.querySelector("#primary");
    const elementMain = document.querySelector("#main");
    const elementPost = document.querySelector("#post-6048");
    if (elementPrimary && elementMain && elementPost) {
      elementPrimary.classList.add("ghwc-primary");
    }
  } catch (e) {
    console.log(e);
  }
};
