import { useEffect, useState } from "react";

const useCountDown = (countDownStart = 5) => {
  const [showCountDownTime, setShowCountDownTime] = useState(countDownStart);
  const [isCountDownActive, setIsCountDownActive] = useState(false);

  useEffect(() => {
    let countDownInstance = null;
    if (isCountDownActive) {
      countDownInstance = setInterval(() => {
        setShowCountDownTime(showCountDownTime - 1);
      }, 1000);
    }
    if (showCountDownTime <= 0 || !isCountDownActive) {
      setIsCountDownActive(false);
      clearInterval(countDownInstance);
    }
    return () => clearInterval(countDownInstance);
  }, [countDownStart, isCountDownActive, showCountDownTime]);

  return [showCountDownTime, setIsCountDownActive, isCountDownActive];
};
export default useCountDown;
