import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./CountDown.scss";
import useCountDown from "../../../hooks/time/useCountDown";

function CountDown(props) {
  const { isCountDownStarted, onFinish, countDownStart } = props;
  const [showCountDownTime, setIsCountDownActive, isCountDownActive] = useCountDown(countDownStart);

  useEffect(() => {
    if (isCountDownStarted) {
      setIsCountDownActive(true);
    }
  }, [isCountDownStarted]);
  useEffect(() => {
    if (!isCountDownActive && showCountDownTime === 0) onFinish();
  }, [showCountDownTime, isCountDownActive]);

  return <span className="gh-widget-countdown">{showCountDownTime}</span>;
}

CountDown.propTypes = {
  isCountDownStarted: PropTypes.bool,
  onFinish: PropTypes.func,
  countDownStart: PropTypes.number,
};
CountDown.defaultProps = {
  onFinish: () => {},
  countDownStart: 5,
  isCountDownStarted: false,
};
export default CountDown;
