import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import "./Checkbox.scss";

const Checkbox = ({ className, id, label, ...rest }) => {
  return (
    <div className={className}>
      <input
        type="checkbox"
        className="checkbox-input"
        id={id}
        {...rest}
      />
      <label
        htmlFor={id}
        className="checkbox-label"
      >
        {label}
        <span className="checkbox"></span>
      </label>
    </div>
  );
};
Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};
Checkbox.defaultProps = {
  className: "",
  label: "",
};
export default Checkbox;
