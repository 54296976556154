import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL, API_HEADERS, API_TIMEOUT } from "../../constants/api";
import { defaultErrorMessage } from "../../constants/otherConstants";

export const axiosClient = axios.create();

axiosClient.defaults.baseURL = API_BASE_URL;

axiosClient.defaults.headers = API_HEADERS;

axiosClient.defaults.timeout = API_TIMEOUT;

axiosClient.defaults.skipDefaultToast = true;

axiosClient.defaults.withCredentials = false;

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = error?.response?.data?.message || defaultErrorMessage;
    if (!axiosClient.defaults.skipDefaultToast) {
      toast.error(message);
    } else {
      console.error(message);
    }

    throw error;
  },
);

export const axiosSetHeader = (header = "", value = "") => {
  // eslint-disable-next-line no-return-assign
  return (axiosClient.defaults.headers[header] = value);
};

export function getRequest(URL, params) {
  return axiosClient.get(`/${URL}`, { params }).then((response) => response);
}

export function postRequest(URL, payload) {
  return axiosClient.post(`/${URL}`, payload).then((response) => response);
}

export function patchRequest(URL, payload) {
  return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL) {
  return axiosClient.delete(`/${URL}`).then((response) => response);
}
