import React from "react";
import PropTypes from "prop-types";
import "./EventContentInfoRow.scss";
import Text from "../../../UI/Text";

const EventContentInfoRow = ({ title, value }) => {
  return (
    <div className="gh-widget-events-info-rows">
      <Text className="gh-widget-events-info-element">{title}</Text>
      <Text className="gh-widget-events-info-element">{value}</Text>
    </div>
  );
};

EventContentInfoRow.propTypes = { title: PropTypes.string, value: PropTypes.string };
EventContentInfoRow.defaultProps = {};
export default EventContentInfoRow;
