import { postRequest } from "../services/axios/axiosClient";

export const captchaCheck = async (recaptchaRef, captchaApiKey) => {
  if (!captchaApiKey) return;
  const captchaResponseToken = await recaptchaRef.current.executeAsync().catch((err) => console.error(err));
  recaptchaRef.current.reset();
  const captchaResp = await postRequest("validate-captcha", { "g-recaptcha-response": captchaResponseToken }).catch(
    (err) => console.error(err),
  );
  return captchaResp?.status === 200;
};

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const loadGoogleMapsApi = () => {
  const googleMapScriptInstance = document.createElement("script");
  googleMapScriptInstance.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places,geometry&language=en`;
  googleMapScriptInstance.attributes.setNamedItem(document.createAttribute("async"));
  document.body.appendChild(googleMapScriptInstance);
};
