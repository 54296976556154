import React from "react";
import PropTypes from "prop-types";
import "./Loader.scss";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as Loadersvg } from "../../../assets/svg/loader.svg";
import cs from "classnames";

function Loader(props) {
  const { className, loading } = props;
  // if (!loading) return "";
  return (
    <div className={cs(`gh-widget-spinner`, className, { "gh-widget-d-none": !loading })}>
      <CSSTransition
        in={loading}
        timeout={400}
        classNames={{
          enterActive: "gh-widget-spinner-enter-active",
          exitActive: "gh-widget-spinner-exit-active",
        }}
        unmountOnExit
      >
        <Loadersvg />
      </CSSTransition>
    </div>
  );
}

Loader.propTypes = { className: PropTypes.string, loading: PropTypes.bool };
Loader.defaultProps = { className: "", loading: true };
export default Loader;
