import { useEffect, useState } from "react";
import { EXACT, INCLUDES, NO_INCLUDES } from "../../constants/otherConstants";
import { useWidgetConfig } from "../../context/widgetConfigContext";

const processingRules = (ruleType, ruleParameter, urlHref) => {
  let result = false;
  // eslint-disable-next-line default-case
  switch (ruleType.toUpperCase()) {
    case INCLUDES:
      if (urlHref.includes(ruleParameter)) result = true;
      break;
    case NO_INCLUDES:
      if (!urlHref.includes(ruleParameter)) result = true;
      break;
    case EXACT:
      if (ruleParameter === urlHref) result = true;
      break;
  }
  return result;
};

let oldUrl = "";
const useUrlRules = () => {
  const { widgetConfig } = useWidgetConfig();
  const { rules } = widgetConfig;
  const urlHref = new URL(window.location.href)?.href;
  const [rulesResult, setRulesResult] = useState(false);
  const [mutation, setMutation] = useState(0);

  useEffect(() => {
    oldUrl = window.location.href;

    function urlSubscription() {
      if (oldUrl !== window.location.href) {
        oldUrl = window.location.href;
        setMutation((old) => old + 1);
      }
    }

    window.addEventListener("hashchange", urlSubscription);

    return () => {
      window.removeEventListener("hashchange", urlSubscription);
    };
  }, []);

  useEffect(() => {
    if (!rules) return;
    const result = rules.map(({ parameter, type }) => processingRules(type, parameter, urlHref))?.includes(true);
    const newState = rules.length > 0 ? result : true;
    setRulesResult(newState);
  }, [rules, urlHref, mutation]);

  return rulesResult;
};
export default useUrlRules;
