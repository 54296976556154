import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { CSSTransition } from "react-transition-group";
import moment from "moment";
import AutoComplete from "../../../UI/AutoComplete";
import { isObjectEmpty } from "../../../../utils/objectUtils";
import ErrorMessageComponent from "../../../UI/ErrorMessageComponent";
import Button from "../../../UI/Button";
import { ReactComponent as UpDownArrows } from "../../../../assets/svg/upDownArrows.svg";
import InputNumberIncrement from "../../../UI/InputNumberIncrement";
import check from "../../../../assets/svg/white-check.svg";
import DateTimePickerInline from "../DateTimePickerInline";
import timeConstants, { dateFormatCompact } from "../../../../constants/timeConstants";
import {
  departureTimeLabel,
  getEstimates,
  oneWayLabel,
  returnLabel,
  returnTimeLabel,
} from "../../../../constants/content";
import { validateArrayAtLeastN } from "../../../../utils/formFalidation";
import { ONE_WAY, ROUND_TRIP } from "../../../../constants/otherConstants";
import WarningMessage from "../../../UI/WarningMessage";

const { MINIMUM_ROUND_TRIP_DURATION, timeFormat } = timeConstants;
const formControllerNames = {
  FROM: "from",
  TO: "to",
};

function ExternalEnquiryFormFlightInfoStep(props) {
  const {
    updateLocationHandler,
    forceStopAutocomplete,
    changeForceStopAutocomplete,
    formLocations,
    swapLocationsAnimation,
    handleLocationsSwap,
    typeOfTrip,
    setTypeOfTrip,
    TripInfoErrors,
    enquiryFormInstance,
    handleGoToOptions,
    show,
    isLoading,
    autocompleteReadOnlyData,
    isStatusSuccess,
  } = props;

  const {
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = enquiryFormInstance;

  const [fromCustomMessage, setFromCustomMessage] = useState(null);
  const [fromLandingFeeCustomMessage, setFromLandingFeeCustomMessage] = useState(null);
  const [toCustomMessage, setToCustomMessage] = useState(null);
  const [toLandingFeeCustomMessage, setToLandingFeeCustomMessage] = useState(null);

  useEffect(() => {
    const { from_location, to_location } = formLocations;

    // FROM
    setFromLandingFeeCustomMessage(from_location?.landing_fee_custom_message || null);
    setFromCustomMessage(from_location?.custom_message || null);

    // TO
    setToLandingFeeCustomMessage(to_location?.landing_fee_custom_message || null);
    setToCustomMessage(to_location?.custom_message || null);
  }, [formLocations]);

  useEffect(() => {
    if (!isStatusSuccess) return;
    setFromCustomMessage(null);
    setFromLandingFeeCustomMessage(null);
    setToCustomMessage(null);
    setToLandingFeeCustomMessage(null);
  }, [isStatusSuccess]);

  const forceSecondaryText = React.useMemo(() => {
    //From
    const fromCategory = formLocations?.from_location?.category ?? autocompleteReadOnlyData?.from_location?.category;
    const fromSecondary_text =
      formLocations?.from_location?.secondary_text ?? autocompleteReadOnlyData?.from_location?.secondary_text;
    // To
    const toCategory = formLocations?.to_location?.category ?? autocompleteReadOnlyData?.to_location?.category;
    const toSecondary_text =
      formLocations?.to_location?.secondary_text ?? autocompleteReadOnlyData?.to_location?.secondary_text;
    // Secondary Text
    return {
      from: fromCategory && fromSecondary_text && `${fromCategory} • ${fromSecondary_text}`,
      to: toCategory && toSecondary_text && `${toCategory} • ${toSecondary_text}`,
    };
  }, [formLocations, autocompleteReadOnlyData]);

  const validateRange = () => {
    const [dep_at, ret_at, return_date, departure_date] = getValues([
      "departure_at",
      "return_at",
      "return_date",
      "departure_date",
    ]);
    const setErr = () =>
      setError("return_at", {
        type: "manual",
        message: "Time of return must be after time of departure",
      });

    if (!dep_at && ret_at) {
      setErr();
      return false;
    }

    if (typeOfTrip === ROUND_TRIP && moment(return_date).isSame(departure_date)) {
      if (
        moment(dep_at, "HH:mm").isAfter(
          moment(ret_at, "HH:mm").subtract(MINIMUM_ROUND_TRIP_DURATION.duration, MINIMUM_ROUND_TRIP_DURATION.unit),
        ) ||
        moment(dep_at, "HH:mm").isSame(moment(ret_at, "HH:mm"))
      ) {
        setErr();
        return false;
      }
    }

    // clearErrors("return_at");
    return true;
  };
  const validateTripStep = async () => {
    const result = await trigger(TripInfoErrors, {});
    const range = validateRange();
    // location validation
    if (!formLocations.from_location || !formLocations.to_location) {
      if (!formLocations.from_location) setError("from", { type: "manual", message: "From location is required!" });
      if (!formLocations.to_location) setError("to", { type: "manual", message: "To location is required!" });
      return;
    }
    // continue if all right
    if (result && range) handleGoToOptions();
  };
  const updateRange = (name, val) => {
    if (!val.format) return;

    const newValue = val.format(timeFormat);
    if (name === "departure_at") {
      setValue("departure_at", newValue);
    }
    if (name === "return_at") {
      setValue("return_at", newValue);
    }
    validateRange();
  };

  const triggerOtherFieldValidation = (onChange) => (value) => {
    onChange(value);
    trigger("passengers");
  };

  return (
    <div className={`gh-widget-step-item ${show ? "active" : ""}`}>
      <div className="">
        <Controller
          control={control}
          name={formControllerNames.FROM}
          rules={{
            required: {
              value:
                isObjectEmpty(autocompleteReadOnlyData) || autocompleteReadOnlyData.dir !== formControllerNames.FROM,
              message: `From location is required!`,
            },
          }}
          render={({ field: { onChange, value, name } }) => (
            <AutoComplete
              placeholder="City, address, event name, postcode, long/lat or helipad"
              onChange={(locationName, locationObject) => {
                clearErrors(name);
                updateLocationHandler("from", locationObject);
                onChange(locationName);
                changeForceStopAutocomplete(false);
              }}
              value={value}
              label="From"
              forceSecondaryText={forceSecondaryText.from}
              forceStopAutocomplete={forceStopAutocomplete}
              readOnlyData={formControllerNames.FROM === autocompleteReadOnlyData.dir ? autocompleteReadOnlyData : null}
            />
          )}
        />
        <div style={{ minHeight: "18px" }}>
          {errors.from?.message ? <ErrorMessageComponent>{errors.from?.message}</ErrorMessageComponent> : ""}
          <WarningMessage message={fromLandingFeeCustomMessage} />
          <WarningMessage message={fromCustomMessage} />
        </div>
      </div>

      <div className="gh-widget-enquiry-form-swap-wrapper">
        <CSSTransition
          in={swapLocationsAnimation}
          timeout={300}
          classNames={{
            exitActive: "gh-widget-animation-flip-exit-active",
          }}
        >
          <Button
            variant="text"
            color="light"
            type="button"
            onClick={handleLocationsSwap}
            className="gh-widget-enquiry-form-swap-button"
          >
            <UpDownArrows className="gh-widget-icon-svg" />
          </Button>
        </CSSTransition>
      </div>
      <div className="">
        <Controller
          control={control}
          name={formControllerNames.TO}
          defaultValue=""
          rules={{
            required: { value: true, message: `To location is required!` },
          }}
          render={({ field: { onChange, value, name } }) => (
            <AutoComplete
              placeholder="City, address, event name, postcode, long/lat or helipad"
              onChange={(locationName, locationObject) => {
                clearErrors(name);
                updateLocationHandler("to", locationObject);
                onChange(locationName);
                changeForceStopAutocomplete(false);
              }}
              value={value}
              label="To"
              forceSecondaryText={forceSecondaryText.to}
              forceStopAutocomplete={forceStopAutocomplete}
              readOnlyData={formControllerNames.TO === autocompleteReadOnlyData.dir ? autocompleteReadOnlyData : null}
            />
          )}
        />
        <div style={{ minHeight: "18px" }}>
          {errors.from?.message ? <ErrorMessageComponent>{errors.from?.message}</ErrorMessageComponent> : ""}
          <WarningMessage message={toLandingFeeCustomMessage} />
          <WarningMessage message={toCustomMessage} />
        </div>
      </div>

      <div className="gh-widget-pax-column">
        <div className="gh-widget-pax-wrapper">
          <div className="gh-widget-number-field">
            <Controller
              control={control}
              name="passengers.number_adults"
              defaultValue={0}
              rules={{
                validate: validateArrayAtLeastN("passengers", getValues),
              }}
              render={({ field: { onChange, value, name } }) => (
                <InputNumberIncrement
                  name={name}
                  label="Adults"
                  className="gh-widget-input"
                  min={0}
                  max={99}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>
          <div className="gh-widget-number-field">
            <Controller
              control={control}
              name="passengers.number_children"
              defaultValue={0}
              render={({ field: { onChange, value, name } }) => (
                <InputNumberIncrement
                  name={name}
                  label="Children"
                  className="gh-widget-input"
                  min={0}
                  max={99}
                  onChange={triggerOtherFieldValidation(onChange)}
                  value={value}
                />
              )}
            />
          </div>
          <div className="gh-widget-number-field">
            <Controller
              control={control}
              name="luggage"
              defaultValue={0}
              render={({ field: { onChange, value, name } }) => (
                <InputNumberIncrement
                  name={name}
                  label="Luggage"
                  className="gh-widget-input"
                  min={0}
                  max={99}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>
        </div>
        <ErrorMessageComponent>{errors.passengers?.number_adults?.message}</ErrorMessageComponent>
      </div>
      <div className="gh-widget-margin-bottom-20">
        <label
          className="gh-widget-label"
          dangerouslySetInnerHTML={{
            __html: "Type of flight",
          }}
        />
        <div className="gh-widget-switcher">
          <button
            className={`  ${typeOfTrip === ROUND_TRIP ? "gh-widget-button-default" : "gh-widget-button-primary"}`}
            type="button"
            onClick={() => setTypeOfTrip(ONE_WAY)}
          >
            <img
              className="gh-widget-switcher-checked"
              src={check}
              alt="check"
            />
            {oneWayLabel}
          </button>
          <button
            className={`  ${typeOfTrip === ROUND_TRIP ? "gh-widget-button-primary" : "gh-widget-button-default"}`}
            type="button"
            onClick={() => setTypeOfTrip(ROUND_TRIP)}
          >
            <img
              className="gh-widget-switcher-checked"
              src={check}
              alt="check"
            />
            {returnLabel}
          </button>
        </div>
      </div>

      <DateTimePickerInline
        control={control}
        setValue={setValue}
        updateRange={updateRange}
        errors={errors}
        clearErrors={clearErrors}
        getValues={getValues}
        dateName="departure_date"
        timeName="departure_at"
        labelDate="Departure Date"
        labelTime={departureTimeLabel}
        isValidBeforeSpecificDate={
          typeOfTrip !== ONE_WAY && getValues("return_date") ? moment(getValues("return_date")).add(1, "days") : null
        }
        timeErrorMessage="Time of departure is required"
        dateErrorMessage="Departure date is required"
        pickOnly
        className="gh-widget-enquiry-date-and-time-picker"
        validateRange={validateRange}
        dateFormat={dateFormatCompact}
      />
      <DateTimePickerInline
        control={control}
        setValue={setValue}
        updateRange={updateRange}
        errors={errors}
        clearErrors={clearErrors}
        getValues={getValues}
        dateName="return_date"
        timeName="return_at"
        visibility={typeOfTrip !== ONE_WAY}
        labelDate="Return Date"
        labelTime={returnTimeLabel}
        isValidAfterSpecificDate={
          typeOfTrip !== ONE_WAY && getValues("departure_date")
            ? moment(getValues("departure_date")).subtract(1, "days")
            : null
        }
        timeErrorMessage="Time of return is required"
        dateErrorMessage="Return date is required"
        pickOnly
        className="gh-widget-enquiry-date-and-time-picker"
        validateRange={validateRange}
        dateFormat={dateFormatCompact}
      />
      <Button
        onClick={validateTripStep}
        color="success"
        type="button"
        className="gh-widget-enquiry-form-continue-btn"
        isLoading={isLoading}
      >
        {getEstimates}
      </Button>
    </div>
  );
}

ExternalEnquiryFormFlightInfoStep.propTypes = {
  updateLocationHandler: PropTypes.func,
  forceStopAutocomplete: PropTypes.bool,
  formLocations: PropTypes.object,
  swapLocationsAnimation: PropTypes.bool,
  handleLocationsSwap: PropTypes.func,
  typeOfTrip: PropTypes.string,
  handleGoToOptions: PropTypes.func,
  setTypeOfTrip: PropTypes.func,
  changeForceStopAutocomplete: PropTypes.func,
  TripInfoErrors: PropTypes.array,
  enquiryFormInstance: PropTypes.object,
  show: PropTypes.bool,
  isLoading: PropTypes.bool,
  autocompleteReadOnlyData: PropTypes.object,
  isStatusSuccess: PropTypes.bool,
};

ExternalEnquiryFormFlightInfoStep.defaultProps = {
  show: true,
  isLoading: false,
  autocompleteReadOnlyData: {},
};
export default ExternalEnquiryFormFlightInfoStep;
