import React from "react";
import PropTypes from "prop-types";
import "./Link.scss";
import cs from "classnames";

const Link = (props) => {
  const { children, className, ...rest } = props;
  return (
    <a
      className={cs("gh-widget-app-link", className)}
      {...rest}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
Link.defaultProps = { className: "" };
export default Link;
