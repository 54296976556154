import React, { useMemo } from "react";
import "./MainTabNavigation.scss";
import Tab from "../../UI/Tabs/Tab";
import TabPaneMain from "../../UI/Tabs/TabPaneMain";
import OnDemand from "../WindowOnDemand/OnDemand";
import EventsWindow from "../WindowEvents/EventsWindow";
import Tabs from "../../UI/Tabs";
import useHasFeature from "../../../hooks/useHasFeature";
import { DESTINATIONS_TAB, EVENTS_TAB, ON_DEMAND_TAB, POWERED_BY_GETHELI } from "../../../constants/features";
import { DESTINATIONS, PUBLIC_EVENTS } from "../../../constants/api";
import EventContent from "../WindowEvents/EventContent";
import DestinationContent from "../WindowEvents/DestinationContent";
import PoweredByGetHeli from "../../common/Tags/PoweredByGetHeli";

const MainTabNavigation = () => {
  const { checkFeature } = useHasFeature();
  const isOnDemandTabHide = useMemo(() => !checkFeature(ON_DEMAND_TAB), [checkFeature, ON_DEMAND_TAB]);
  const isEventsTabHide = useMemo(() => !checkFeature(EVENTS_TAB), [checkFeature, EVENTS_TAB]);
  const isDestinationsTabHide = useMemo(() => !checkFeature(DESTINATIONS_TAB), [checkFeature, DESTINATIONS_TAB]);
  const hasFeaturePoweredByGetheli = checkFeature(POWERED_BY_GETHELI);

  // if you add a new tab, you need to add it to the tabs (navigationTabs) array in src/constants/features.js
  return (
    <Tabs className="gh-widget-main-tabs">
      <Tab
        title="On Demand"
        hide={isOnDemandTabHide}
        className="gh-widget-tab-on-demand"
      >
        <TabPaneMain>
          <OnDemand />
          <PoweredByGetHeli
            show={hasFeaturePoweredByGetheli}
            className="gh-widget-complex-layout-poweredBy"
          />
        </TabPaneMain>
      </Tab>
      <Tab
        title="Events"
        className="gh-widget-tab-event-wrapper"
        hide={isEventsTabHide}
      >
        <EventContainer />
        <PoweredByGetHeli
          show={hasFeaturePoweredByGetheli}
          className="gh-widget-complex-layout-poweredBy"
        />
      </Tab>
      <Tab
        title="Destinations"
        className="gh-widget-tab-event-wrapper"
        hide={isDestinationsTabHide}
      >
        <DestinationsContainer />
        <PoweredByGetHeli
          show={hasFeaturePoweredByGetheli}
          className="gh-widget-complex-layout-poweredBy"
        />
      </Tab>
    </Tabs>
  );
};

MainTabNavigation.propTypes = {};
MainTabNavigation.defaultProps = {};
export default MainTabNavigation;

const EventContainer = () => {
  return (
    <EventsWindow endpoint={PUBLIC_EVENTS}>
      <EventContent />
    </EventsWindow>
  );
};

const DestinationsContainer = () => {
  return (
    <EventsWindow endpoint={DESTINATIONS}>
      <DestinationContent />
    </EventsWindow>
  );
};
