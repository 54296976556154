/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useContext, useEffect, useState } from "react";
import MarkerInfoContext from "./MarkerInfoContext";
import cardMapMarker from "../../../../assets/svg/cardMapMarker.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/svg/mapArrowRight.svg";
import { placeTypeIcon } from "../Map.context";
import { generateChosenSiteText, renderTitleForPad } from "../Map.utils";

function DefaultMarker() {
  const {
    pin,
    pin: { name, image, rating, comments_count, category, handleAssignButton, view_link, direction },
    isSelected,
    updateChosenSitesByRole,
  } = useContext(MarkerInfoContext);
  const [showInfo, setShowInfo] = useState(false);
  const [chosenSiteText, setChosenSiteText] = useState();

  useEffect(() => {
    setChosenSiteText(pin.chosenSiteText || generateChosenSiteText(direction, updateChosenSitesByRole));
  }, [direction]);

  return (
    <div
      className="legInfoWindow"
      id="id-info"
    >
      {!!image && (
        <div
          className="imageBox"
          style={{
            background: `url(${image}) center center / cover no-repeat`,
          }}
        />
      )}
      <div className="content">
        <h4 className="top-head">{renderTitleForPad(view_link, name)}</h4>
        <div className="pin-info-grid">
          <span className="item-icon">
            <img
              src={placeTypeIcon(category)}
              alt="cutleryIcon"
            />{" "}
            <span className="text">{category}</span>
          </span>
          <span className="item-icon">
            <img
              src={cardMapMarker}
              alt="cardMapMarker"
            />{" "}
            <span className="text">-</span>
          </span>
          {/* <span className="item-icon"> */}
          {/*   <img */}
          {/*     src={mapCardStar} */}
          {/*     alt="mapCardStar" */}
          {/*   />{" "} */}
          {/*   <span className="text">{rating || 0}</span> */}
          {/* </span> */}
          {/* <span className="item-icon"> */}
          {/*   <img */}
          {/*     src={reviewIcon} */}
          {/*     alt="reviewIcon" */}
          {/*   />{" "} */}
          {/*   <span className="text">{comments_count || 0} reviews</span> */}
          {/* </span> */}
        </div>
        {isSelected && direction ? (
          <div className="legInfoWindowActionLegs">
            <h4 className="bottom-head">{chosenSiteText}</h4>
          </div>
        ) : (
          <div className="legInfoWindowActionLegs">
            {showInfo ? (
              <div>
                <a
                  role="button"
                  onClick={() => handleAssignButton("from")}
                  className="gh-widget-app-map-card-from-btn"
                >
                  FROM
                </a>
                <span style={{ margin: "0 15px", fontWeight: 600, fontSize: 14 }}>or</span>
                <a
                  role="button"
                  onClick={() => handleAssignButton("to")}
                  className="gh-widget-app-map-card-to-btn"
                >
                  TO
                </a>
              </div>
            ) : (
              <a onClick={() => setShowInfo(true)}>
                <h4 className="bottom-head secondary">
                  Use this helipad <ArrowRight />
                </h4>
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(DefaultMarker);
