import React from "react";
import PhoneInput2 from "react-phone-input-2";
import PropTypes from "prop-types";
import { condStrings } from "../../../utils/stringUtils";
import "react-phone-input-2/lib/style.css";
import "./InputPhoneNumber.scss";

function InputPhoneNumber(props) {
  const { containerClass, inputClass, name, onChange, selectedCountry, value, ...rest } = props;

  const handleOnChange = (inpValue, { countryCode, dialCode }) => {
    onChange({ inpValue, code: countryCode ? countryCode.toUpperCase() : "", dialCode });
  };

  return (
    <div className="phone-input_wrapper">
      <PhoneInput2
        {...rest}
        containerClass={condStrings("phone-input-container", containerClass)}
        inputClass={condStrings("", inputClass)}
        country={selectedCountry.toLowerCase()}
        onChange={handleOnChange}
        specialLabel={null}
        enableAreaCodes
        value={typeof value === "object" && !!value ? value.value : value || ""}
        inputProps={{
          name,
        }}
      />
    </div>
  );
}

InputPhoneNumber.defaultProps = {
  containerClass: "",
  inputClass: "",
  name: "",
  onChange: () => null,
  selectedCountry: "gb",
};

InputPhoneNumber.propTypes = {
  containerClass: PropTypes.string,
  inputClass: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  selectedCountry: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default InputPhoneNumber;
