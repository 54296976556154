import React from "react";
import { useWidgetConfig } from "../../../../context/widgetConfigContext";
import { initialMapCoordinates } from "../../../../constants/mapConfig";
import { useForceUpdate } from "../../../../hooks/useForceUpdate";
import PadsSelection from "../../../shared/Map/PadsSelection";
import useMapStore from "../../../../store/useMapStore";
import { onDemandDescription } from "../../../../constants/content";
import { ROUND_TRIP } from "../../../../constants/otherConstants";

const OnDemandContent = () => {
  const { externalEnquiryFormStep } = useWidgetConfig();
  const setMapCoordinates = useMapStore((state) => state.setMapCoordinates);
  const mapCoordinates = useMapStore((state) => state.mapCoordinates);

  const forceUpdate = useForceUpdate();

  React.useEffect(() => {
    setMapCoordinates(initialMapCoordinates);
    // force reinitialize map, fix for widget type iFrame
    window.addEventListener("load", () => {
      forceUpdate();
    });
  }, []);

  const flyCoordinates = React.useMemo(() => {
    if (mapCoordinates.typeOfTrip === ROUND_TRIP) {
      const mapCoordinatesCopy = {
        key: "map-location-copy",
        from: mapCoordinates?.to,
        to: mapCoordinates?.from,
        pad: { from_location: mapCoordinates?.pad?.to_location, to_location: mapCoordinates?.pad?.from_location },
      };

      return [mapCoordinatesCopy, mapCoordinates];
    }

    return [mapCoordinates];
  }, [mapCoordinates]);

  return (
    <>
      {/*<div className="gh-mobile-map-legend">*/}
      {/*  <MapLegend />*/}
      {/*</div>*/}

      <div className="on-demand-map-wrapper">
        <PadsSelection
          showPadsInRadius={externalEnquiryFormStep === 0}
          flyCoordinates={flyCoordinates}
        />
      </div>

      {/*<div className="gh-desktop-map-legend">*/}
      {/*  <MapLegend />*/}
      {/*</div>*/}

      {/* Maintains container width */}
      <div className="gh-widget-app-on-demand-information">
        <p>{onDemandDescription}</p>
      </div>
    </>
  );
};
OnDemandContent.propTypes = {};
export default OnDemandContent;
