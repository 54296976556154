import React, { useMemo } from "react";
import PropTypes from "prop-types";

import "./Tabs.scss";

function Tabs({ children, className, titleClassName }) {
  const filteredTabs = useMemo(() => children?.filter((tab) => !tab.props?.hide), [children]);

  const [selectedTab, setSelectedTab] = React.useState(0);
  const tabs = filteredTabs?.length ? filteredTabs : [filteredTabs];
  const content = filteredTabs?.length ? filteredTabs[selectedTab] : filteredTabs;

  if (!filteredTabs) return "";
  return (
    <div className={className}>
      {tabs?.length > 1 ? (
        <ul className="gh-widget-tabs-new-design">
          {tabs.map((item, index) => (
            <TabTitle
              className={titleClassName}
              key={index}
              title={item.props.title}
              index={index}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          ))}
        </ul>
      ) : null}

      {content}
    </div>
  );
}

Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
};
Tabs.defaultProps = {
  className: "",
  titleClassName: "",
};

const TabTitle = ({ title, setSelectedTab, index, selectedTab, className }) => {
  const onClick = React.useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  const isActiveClass = React.useMemo(() => {
    return selectedTab === index ? "active" : "";
  }, [selectedTab, index]);

  return (
    <li
      className={`gh-widget-tabs-new-design-tab ${isActiveClass} ${className}`}
      onClick={onClick}
    >
      {title}
    </li>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
  className: PropTypes.string,
};
TabTitle.defaultProps = {
  className: "",
};

export default Tabs;
