import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import chillout from "chillout";
import { GridAlgorithm, MarkerClusterer } from "@googlemaps/markerclusterer";
import usePrevious from "../../../hooks/usePrevious";
import { InfoWindow } from "google-maps-react";
import { secondaryIcon } from "./Map.context";
import clusterCircle from "../../../assets/svg/clusterCircle.svg";
import MarkerInfo from "./MarkerInfo";
import isMobile from "../../../utils/isMobile";
import { useWidgetConfig } from "../../../context/widgetConfigContext";

export default function MarkerCluster(props) {
  const { map, google, pads, setActiveMarker, activeMarker, mapId, closeMarkerInfo } = props;
  const { externalEnquiryFormStep } = useWidgetConfig();

  const clusterer = React.useRef(null);

  const previousPadsLength = usePrevious(pads.length);

  const [markers, setMarkers] = React.useState([]);

  const markerMouseOverHandler = React.useCallback(
    (marker = null, pad = null) =>
      () => {
        if (marker) {
          setActiveMarker({
            visible: true,
            pad,
            marker,
            isSelected: false,
          });
        }
      },
    [setActiveMarker],
  );

  const onInfoWindowOpen = () => {
    const MarkerContent = (
      <MarkerInfo
        key={mapId}
        pin={activeMarker.pad}
        isSelected={activeMarker.isSelected}
        actions={true}
        updateChosenSitesByRole={false}
      />
    );
    ReactDOM.render(React.Children.only(MarkerContent), document.getElementById(mapId));
  };

  const clearMarkers = () => {
    markers.forEach((marker) => {
      marker.setMap(null);
    });

    if (clusterer.current) {
      clusterer.current.clearMarkers();
    }

    setMarkers([]);
  };

  React.useEffect(() => {
    if (map && pads.length !== previousPadsLength) {
      if (pads.length === 0) {
        clearMarkers();
      } else {
        chillout
          .forEach(pads, (pad) => {
            const marker = new google.maps.Marker({
              position: {
                lat: pad.location.latitude,
                lng: pad.location.longitude,
              },
              map,
              name: pad.name,
              title: pad.name,
              icon: secondaryIcon(pad.category),
              zIndex: 1000,
            });

            if (isMobile()) {
              marker.addListener("click", markerMouseOverHandler(marker, pad));
            } else {
              marker.addListener("mouseover", markerMouseOverHandler(marker, pad));
            }
            markers.push(marker);
          })
          .then(() => {
            if (clusterer.current) {
              clusterer.current.clearMarkers();
              clusterer.current.addMarkers(markers);

              setTimeout(() => {
                if (clusterer.current.repaint) {
                  clusterer.current.repaint();
                }
              }, 14);
            } else {
              const algorithm = new GridAlgorithm({
                gridSize: 80,
                maxZoom: 9,
              });

              const renderer = {
                render: ({ count, position }) =>
                  new google.maps.Marker({
                    label: {
                      text: String(count),
                      fontWeight: "bold",
                      textColor: "#242424",
                    },
                    icon: { url: clusterCircle },
                    position,
                    // adjust zIndex to be above other markers
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                  }),
              };

              if (externalEnquiryFormStep !== 0) {
                setMarkers([]);
              }

              clusterer.current = new MarkerClusterer({ map, markers, algorithm, renderer });
            }
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, markerMouseOverHandler, pads, previousPadsLength, externalEnquiryFormStep]);

  return (
    <InfoWindow
      marker={activeMarker.marker}
      google={google}
      map={map}
      visible={activeMarker.visible}
      onOpen={onInfoWindowOpen}
      onClose={closeMarkerInfo}
    >
      <div id={mapId} />
    </InfoWindow>
  );
}

MarkerCluster.propTypes = {
  pads: PropTypes.array,
  setActiveMarker: PropTypes.func,
  map: PropTypes.object,
  google: PropTypes.object,
  mapId: PropTypes.string,
  activeMarker: PropTypes.object,
  closeMarkerInfo: PropTypes.func,
};

MarkerCluster.defaultProps = {
  pads: [],
};
