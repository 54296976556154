export default function isMobile() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }

  if (window.innerWidth < 768) {
    return true;
  }

  return false;
}
