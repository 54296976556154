import React from "react";
import PropTypes from "prop-types";
import Button from "../../../UI/Button";
import Checkbox from "../../../UI/Checkbox";
import ConditionalRendering from "../../../UI/ConditionalRendering";
import cs from "classnames";

import "./EventsFilter.scss";

const titlesDefault = {
  countries: "COUNTRY",
  type: "FLIGHT TYPE",
};

const EventsFilter = ({ checkedFilters, setCheckedFilters, eventsFiltersLabelsMemoized, titles }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const combinedFilters = Object.entries(eventsFiltersLabelsMemoized)?.reduce((acc, [fKey, fValue]) => {
    const transformedFilters = fValue?.map((filter) => ({ value: filter, type: fKey }));
    const title = titles[fKey] || titlesDefault[fKey];
    return [...acc, { title, filters: transformedFilters }];
  }, []);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const [filter, filterType] = value.split("|");

    if (checked) {
      setCheckedFilters((prev) => ({
        ...prev,
        [filterType]: [...prev[filterType], filter],
      }));
    } else {
      setCheckedFilters((prev) => ({
        ...prev,
        [filterType]: [...prev[filterType].filter((f) => f !== filter)],
      }));
    }
  };

  const isButtonDisabled = Object.values(eventsFiltersLabelsMemoized).length === 0;

  const toggleFilter = () => setIsOpen(!isOpen);
  return (
    <>
      <Button
        variant="text"
        color="dark"
        className={cs("gh-widget-events-filter-button", { "gh-widget-events-filter-button--open": isOpen })}
        onClick={toggleFilter}
        disabled={isButtonDisabled}
      >
        <div className="gh-widget-events-filter-button_text">Filters</div>
        <FilterSVG style={{ marginLeft: 5 }} />
      </Button>
      <ConditionalRendering showComponent={isOpen}>
        <div
          className="gh-widget-events-filter"
          role="button"
          onClick={toggleFilter}
        >
          <div
            className="gh-widget-events-filter-menu"
            onClick={(e) => e.stopPropagation()}
          >
            {combinedFilters.map((filterGroup) => {
              return (
                <div
                  className="gh-widget-filter-group"
                  key={filterGroup.title}
                >
                  <h4
                    key={filterGroup.title}
                    className="gh-widget-filter-menu_title"
                  >
                    {filterGroup.title}
                  </h4>
                  {filterGroup.filters.map(({ value, type }) => {
                    return (
                      <Checkbox
                        className="gh-widget-filter-menu_checkbox"
                        id={value.split(" ").join("_")}
                        key={value}
                        label={value}
                        value={value + "|" + type}
                        checked={checkedFilters[type]?.includes(value)}
                        onChange={handleCheckboxChange}
                      />
                    );
                  })}
                </div>
              );
            })}
            <Button
              variant="contained"
              color="light"
              onClick={setCheckedFilters.bind(null, { countries: [], type: [] })}
              className="gh-widget-app-reset-filters"
            >
              Reset Filters
            </Button>
          </div>
        </div>
      </ConditionalRendering>
    </>
  );
};

EventsFilter.propTypes = {
  eventsFiltersLabelsMemoized: PropTypes.array.isRequired,
  checkedFilters: PropTypes.object.isRequired,
  setCheckedFilters: PropTypes.func.isRequired,
  titles: PropTypes.object,
};

EventsFilter.defaultProps = {
  titles: {},
};

export default EventsFilter;

const FilterSVG = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    data-svg="settings"
    {...props}
  >
    <ellipse
      fill="none"
      stroke="#000"
      cx="6.11"
      cy="3.55"
      rx="2.11"
      ry="2.15"
    ></ellipse>
    <ellipse
      fill="none"
      stroke="#000"
      cx="6.11"
      cy="15.55"
      rx="2.11"
      ry="2.15"
    ></ellipse>
    <circle
      fill="none"
      stroke="#000"
      cx="13.15"
      cy="9.55"
      r="2.15"
    ></circle>
    <rect
      x="1"
      y="3"
      width="3"
      height="1"
    ></rect>
    <rect
      x="10"
      y="3"
      width="8"
      height="1"
    ></rect>
    <rect
      x="1"
      y="9"
      width="8"
      height="1"
    ></rect>
    <rect
      x="15"
      y="9"
      width="3"
      height="1"
    ></rect>
    <rect
      x="1"
      y="15"
      width="3"
      height="1"
    ></rect>
    <rect
      x="10"
      y="15"
      width="8"
      height="1"
    ></rect>
  </svg>
);

const eventsFilter = [
  { title: "Event Location", filters: ["Monaco", "United Kingdom", "France"] },
  {
    title: "Event Location",
    filters: [
      "Auto, Boat & Motor",
      "Fashion",
      "Horse Racing",
      "Flower Show",
      "Equestrian",
      "Festival",
      "Tennis",
      "Film",
      "Golf",
      "Opera",
      "Polo",
      "Other",
    ],
  },
];
