import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import ErrorMessageComponent from "../../../../UI/ErrorMessageComponent";
import InputPhoneNumber from "../../../../UI/InputPhoneNumber";
import Button from "../../../../UI/Button";
import {
  additionalInfoLabel,
  backLabel,
  specialCommentsPlaceholder,
  submitLabel,
} from "../../../../../constants/content";
import useGdprText from "./useGdprText";

function ExternalEnquiryFormPersonalInfoStep(props) {
  const { isLoading, show, enquiryFormInstance, handlePersonalInfoBack } = props;
  if (!show) return "";

  const {
    control,
    formState: { errors },
  } = enquiryFormInstance;

  return (
    <div className={`gh-widget-step-item gh-widget-enquire-margin ${show ? "active" : ""}`}>
      <div className="gh-widget-input-group ">
        <div className="gh-widget-input-wrapper gh-widget-margin-right-5">
          <label
            className="gh-widget-label"
            dangerouslySetInnerHTML={{
              __html: "First name",
            }}
          />
          <Controller
            control={control}
            name="first_name"
            rules={{ required: { value: true, message: "First Name is required" } }}
            render={({ field }) => (
              <input
                {...field}
                placeholder="John"
                className="gh-widget-old-input gh-widget-enquire-form-input-rounder"
                autoComplete="given-name"
              />
            )}
          />
          <ErrorMessageComponent>{errors.first_name?.message}</ErrorMessageComponent>
        </div>
        <div className="gh-widget-input-wrapper">
          <label
            className="gh-widget-label"
            dangerouslySetInnerHTML={{
              __html: "Last name",
            }}
          />
          <Controller
            control={control}
            name="last_name"
            rules={{ required: { value: true, message: "Last Name is required" } }}
            render={({ field }) => (
              <input
                {...field}
                placeholder="Smith"
                className="gh-widget-old-input gh-widget-enquire-form-input-rounder"
                autoComplete="family-name"
              />
            )}
          />
          <ErrorMessageComponent>{errors.last_name?.message}</ErrorMessageComponent>
        </div>
      </div>
      <div className="gh-widget-input-wrapper">
        <label
          className="gh-widget-label"
          dangerouslySetInnerHTML={{
            __html: "Your Email",
          }}
        />
        <Controller
          control={control}
          name="email"
          rules={{
            required: { value: true, message: "Email is required" },
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Please enter a valid email",
            },
          }}
          render={({ field }) => (
            <input
              {...field}
              placeholder="example@mail.com"
              className="gh-widget-old-input gh-widget-enquire-form-input-rounder"
            />
          )}
        />
        <ErrorMessageComponent>{errors.email?.message}</ErrorMessageComponent>
      </div>
      <div className="gh-widget-input-wrapper">
        <label
          className="gh-widget-label"
          dangerouslySetInnerHTML={{
            __html: "Phone number",
          }}
        />
        <Controller
          control={control}
          name="phone"
          rules={{
            validate: phoneNameValidate,
          }}
          render={({ field: { ref, ...field } }) => <InputPhoneNumber {...field} />}
        />
        <ErrorMessageComponent>{errors.phone?.message}</ErrorMessageComponent>
      </div>
      <div className="gh-widget-input-wrapper gh-widget-margin-bottom-20">
        <label className="gh-widget-label">{additionalInfoLabel}</label>
        <Controller
          control={control}
          name="additional_information"
          render={({ field }) => (
            <textarea
              className="gh-widget-textarea"
              placeholder={specialCommentsPlaceholder}
              {...field}
            />
          )}
        />
      </div>
      <div className="gh-widget-enquiry-buttons-wrapper">
        <Button
          onClick={handlePersonalInfoBack}
          color="light"
          variant="contained"
          type="button"
        >
          <span>{backLabel}</span>
        </Button>
        <Button
          type="submit"
          isLoading={isLoading}
        >
          {submitLabel}
        </Button>
      </div>
      <GdprAgreement />
    </div>
  );
}

ExternalEnquiryFormPersonalInfoStep.propTypes = {
  show: PropTypes.bool,
  isLoading: PropTypes.bool,
  enquiryFormInstance: PropTypes.object,
  handlePersonalInfoBack: PropTypes.func,
};
export default ExternalEnquiryFormPersonalInfoStep;

const phoneNameValidate = (value) => {
  if (!value) return true;

  const { inpValue, dialCode } = value;

  const error = "Your number cannot start with 0";
  const numberWithOutCode = inpValue.substring(dialCode.length);

  if (numberWithOutCode.charAt(0) === "0") {
    return error;
  }

  const errorMessage = "Please enter a valid phone number";
  const isOnlyCountryCode = inpValue === dialCode;
  const ifNumberLengthInValid = inpValue?.length <= dialCode?.length;

  if ((ifNumberLengthInValid || isOnlyCountryCode) && errorMessage) {
    return true;
  }
};

const GdprAgreement = () => {
  const { GdprText } = useGdprText();
  return (
    <p className="gh-widget-color-secondary gh-widget-text-xs gh-widget-gdpr">
      <GdprText />
    </p>
  );
};
