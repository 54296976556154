import React, { useEffect } from "react";
import "./TooltipsDefine.scss";
import ReactTooltip from "react-tooltip";
import { useWidgetConfig } from "../../../context/widgetConfigContext";

/*
  There we need to define tooltips to avoid conflict with react-modal
  https://github.com/wwayne/react-tooltip#1-using-tooltip-within-the-modal-eg-react-modal
*/

function TooltipsDefine() {
  const {
    globalState: { tooltips },
  } = useWidgetConfig();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tooltips]);

  return (
    <div className="gh-widget-tooltip-define hereis">
      {Object.entries(tooltips).map(([_, value]) =>
        value.map((tooltipValue) => {
          const description = tooltipValue.descriptions || tooltipValue.description;

          return (
            <ReactTooltip
              backgroundColor="#242f42"
              className="gh-widget-tooltip-content"
              id={tooltipValue.id}
              effect={description === "Featured Event" || description === "Featured Destination" ? "float" : "solid"}
              key={tooltipValue.id}
              eventOff="mouseleave mouseout scroll mousewheel blur"
            >
              <span dangerouslySetInnerHTML={{ __html: description }} />
            </ReactTooltip>
          );
        }),
      )}
    </div>
  );
}

TooltipsDefine.propTypes = {};
TooltipsDefine.defaultProps = {};
export default TooltipsDefine;
