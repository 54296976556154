import generateLocationAdditionalInfo from "../../../utils/generateLocationAdditionalInfo";
import { LondonLocation, offset } from "./Map.context";
import { ReactComponent as LinkSVG } from "../../../assets/svg/externalLink.svg";
import React from "react";

export const parseCoordinates = (coordinates) => {
  if (!coordinates || typeof coordinates !== "string" || coordinates.length < 1) {
    return false;
  }

  const chunks = coordinates.split("|");

  return {
    lat: parseFloat(chunks[0]),
    lng: parseFloat(chunks[1]),
  };
};

export const getNearestPads = (sites, offset) => (coords) =>
  sites.filter(
    ({ location: { latitude, longitude } }) =>
      latitude >= coords.lat - offset &&
      latitude <= coords.lat + offset &&
      longitude >= coords.lng - offset &&
      longitude <= coords.lng + offset,
  );

export const loadAvailablePads = (currentCoordinates = { from: "", to: "" }, sites) => {
  const loadNearestPads = getNearestPads(sites, offset);

  const nearestPads = [];

  if (currentCoordinates.from) {
    nearestPads.push(...loadNearestPads(parseCoordinates(currentCoordinates.from)));
  }

  if (currentCoordinates.to) {
    nearestPads.push(...loadNearestPads(parseCoordinates(currentCoordinates.to)));
  }

  if (!currentCoordinates.from && !currentCoordinates.to) {
    nearestPads.push(...loadNearestPads(LondonLocation));
  }

  return sites; // nearestPads;
};

export const getAvailablePadsMarkers = (pads = [], currentCoordinates) =>
  pads
    .filter(
      (pad, index, arr) =>
        index ===
        arr.findIndex(
          (pad1) =>
            pad1.location.latitude === pad.location.latitude && pad1.location.longitude === pad.location.longitude,
        ),
    )
    .filter(
      (pad) =>
        ![currentCoordinates.from, currentCoordinates.to].includes(
          `${pad.location.latitude}|${pad.location.longitude}`,
        ),
    )
    .map((pad) => ({
      ...pad,
      category: returnNormalPlaceType(pad.category.display_name || pad.category),
      type: pad?.type,
      image: pad.upload && pad.upload.file_url ? pad.upload.file_url : "",
      source: pad?.source || "hellipaddy",
      secondary_text: generateLocationAdditionalInfo({
        ...pad.location,
        type: pad?.type,
        category: pad.category.display_name,
      }),
      place_id: pad?.id || pad?.place_id,
      location: { ...pad.location, country: pad.location.country === "GB" ? "UK" : pad.location.country },
    }));

export const getPadsInRadius = (prevCoordinates = {}, currentCoordinates, showDefaultPads = false, landingSites) => {
  if (
    showDefaultPads ||
    prevCoordinates.from !== currentCoordinates.from ||
    !prevCoordinates.to !== currentCoordinates.to
  ) {
    return getAvailablePadsMarkers(loadAvailablePads(currentCoordinates, landingSites), currentCoordinates);
  }
};

export function returnNormalPlaceType(type) {
  if (type) {
    if (type.toLowerCase().includes("event")) return "Event";
    if (type.toLowerCase().includes("point")) return "Point of Interest";

    return type;
  }
}

export const getLandingSitesMarkers = (leg, sites) => {
  const updatedLegInfo = {};
  const legLandingSites = {};

  const padTo = {
    to_type: (leg.to_location && leg.to_location.type) || leg.to_type,
    to_coords: (leg.to_location && `${leg.to_location.latitude}|${leg.to_location.longitude}`) || leg.to_coords,
    to_source: (leg.to_location && leg.to_location.source) || leg.to_source,
  };

  const padFrom = {
    from_type: (leg.from_location && leg.from_location.type) || leg.from_type,
    from_coords:
      (leg.from_location && `${leg.from_location.latitude}|${leg.from_location.longitude}`) || leg.from_coords,
    from_source: (leg.from_location && leg.from_location.source) || leg.from_source,
  };

  if ((leg.to_location && leg.to_location.type) || leg.to_type) {
    legLandingSites.legTo = sites
      .filter((site) => `${site.location.latitude}|${site.location.longitude}` === padTo.to_coords)
      .find(
        (site) =>
          site.source === padTo.to_source || site.category.display_name.toLowerCase() === padTo.to_type.toLowerCase(),
      );
  }

  if ((leg.from_location && leg.from_location.type) || leg.from_type) {
    legLandingSites.legFrom = sites
      .filter((site) => `${site.location.latitude}|${site.location.longitude}` === padFrom.from_coords)
      .find(
        (site) =>
          site.source === padFrom.from_source ||
          site.category.display_name.toLowerCase() === padFrom.from_type.toLowerCase(),
      );
  }

  if (legLandingSites.legTo) {
    updatedLegInfo.to = {
      name: legLandingSites.legTo.name,
      type: legLandingSites.legTo?.type,
      category: returnNormalPlaceType(legLandingSites.legTo.category.display_name),
      rating: legLandingSites.legTo.rating,
      image: legLandingSites.legTo.upload && legLandingSites.legTo.upload.file_url,
      comments_count: legLandingSites.legTo.comments_count,
      source: legLandingSites.legTo.source || "hellipaddy",
      start_date: legLandingSites.legTo.start_date,
      end_date: legLandingSites.legTo.end_date,
      view_link: legLandingSites.legTo.view_link,
      direction: "to",
    };
  } else {
    updatedLegInfo.to = {
      name: leg.to_location?.name,
      type: returnNormalPlaceType(leg.to_location?.type),
      source: leg.to_location?.source,
      direction: "to",
      comments_count: leg.to_location?.comments_count,
      image: leg.to_location?.upload && leg.to_location?.upload.file_url,
      category: returnNormalPlaceType(leg.to_location?.category),
      view_link: leg.to_location?.url || leg.to_location?.view_link,
      start_date: leg.to_location?.start_date,
      end_date: leg.to_location?.end_date,
      rating: leg.to_location?.rating,
    };
  }

  if (legLandingSites.legFrom) {
    updatedLegInfo.from = {
      name: legLandingSites.legFrom.name,
      type: legLandingSites.legFrom?.type,
      category: returnNormalPlaceType(legLandingSites.legFrom.category.display_name),
      rating: legLandingSites.legFrom.rating,
      image: legLandingSites.legFrom.upload && legLandingSites.legFrom.upload.file_url,
      comments_count: legLandingSites.legFrom.comments_count,
      source: legLandingSites.legFrom.source || "hellipaddy",
      start_date: legLandingSites.legFrom.start_date,
      end_date: legLandingSites.legFrom.end_date,
      view_link: legLandingSites.legFrom.view_link,
      direction: "from",
    };
  } else {
    updatedLegInfo.from = {
      name: leg.from_location?.name,
      type: returnNormalPlaceType(leg.from_location?.type),
      source: leg.from_location?.source,
      direction: "from",
      comments_count: leg.from_location?.comments_count,
      image: leg.from_location?.upload && leg.from_location?.upload.file_url,
      category: returnNormalPlaceType(leg.from_location?.category),
      view_link: leg.from_location?.url || leg.from_location?.view_link,
      start_date: leg.from_location?.start_date,
      end_date: leg.from_location?.end_date,
      rating: leg.from_location?.rating,
    };
  }

  return updatedLegInfo;
};

export function generateChosenSiteText(dir) {
  if (dir === "from") {
    return "This is your chosen take-off site";
  } else {
    return "This is your chosen landing site";
  }
}

const formatViewLink = (view_link) => {
  if (!view_link) return;

  if (view_link && !view_link.includes("http")) {
    return `https://${view_link}`;
  }

  return view_link;
};

export const renderTitleForPad = (view_link, name) => {
  return view_link ? (
    <a
      href={formatViewLink(view_link)}
      target="_blank"
      rel="noreferrer"
      className="gh-widget-event-card-link"
    >
      {name} <LinkSVG className="gh-link-icon" />
    </a>
  ) : (
    name
  );
};
