import React, { useState } from "react";
import "./LayoutWidget.scss";
import ButtonEnquiry from "../../common/ButtonEnquiry";
import ModalEnquiryForm from "../../common/ModalEnquiryForm";

function LayoutWidget() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalToggle = () => setIsModalOpen(!isModalOpen);
  const modalClose = () => setIsModalOpen(false);

  return (
    <div className="gh-widget-wrapper">
      <ButtonEnquiry onClick={modalToggle} />
      <ModalEnquiryForm
        isModalOpen={isModalOpen}
        closeModal={modalClose}
      />
    </div>
  );
}

LayoutWidget.propTypes = {};
LayoutWidget.defaultProps = {};
export default LayoutWidget;
