import React from "react";
import PropTypes from "prop-types";
import "./LineSeparator.scss";
import cs from "classnames";

const LineSeparator = ({ color, height, className }) => {
  return (
    <hr
      style={{
        color,
        backgroundColor: color,
        height,
      }}
      className={cs("gh-widget-app-line-separator", className)}
    />
  );
};

LineSeparator.propTypes = { color: PropTypes.string, height: PropTypes.number, className: PropTypes.string };
LineSeparator.defaultProps = { color: "var(--gh-widget-ui-secondary-color)", height: 1 };
export default LineSeparator;
