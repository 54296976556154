import React from "react";
import PropTypes from "prop-types";
import "./Tag.scss";
import Text from "../../../UI/Text";
import cs from "classnames";
import ConditionalRendering from "../../../UI/ConditionalRendering";
import { useWidgetConfig } from "../../../../context/widgetConfigContext";
import ReactTooltip from "react-tooltip";

const Tag = (props) => {
  const {
    Icon,
    label,
    className,
    leftOffset,
    topOffset,
    bottomOffset,
    rightOffset,
    show,
    showIcon,
    tooltipContent,
    tooltipId,
    onClick,
  } = props;
  const { setGlobalState, globalState } = useWidgetConfig();

  const left = React.useMemo(() => (leftOffset ? leftOffset + "px" : "initial"), [leftOffset]);
  const top = React.useMemo(() => (topOffset ? topOffset + "px" : "initial"), [topOffset]);
  const bottom = React.useMemo(() => (bottomOffset ? bottomOffset + "px" : "initial"), [bottomOffset]);
  const right = React.useMemo(() => (rightOffset ? rightOffset + "px" : "initial"), [rightOffset]);

  React.useEffect(() => {
    if (!tooltipId || !tooltipContent || !globalState.tooltips?.tags) return;
    const isTooltipDefined = globalState.tooltips.tags.find(({ id }) => id === tooltipId);
    if (isTooltipDefined) return;

    setGlobalState((prevState) => ({
      ...prevState,
      tooltips: {
        ...prevState.tooltips,
        tags: [...prevState.tooltips.tags, { id: tooltipId, descriptions: tooltipContent }],
      },
    }));
  }, [tooltipId]);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  if (!show) return "";
  return (
    <div
      data-tip={tooltipContent}
      data-iscapture="true"
      data-for={tooltipId}
      className="gh-widget-tooltip-trigger"
      onClick={onClick}
    >
      <div
        className={cs(className, "gh-widget-image-tag")}
        style={{ left: left, top: top, right: right, bottom: bottom }}
      >
        <ConditionalRendering showComponent={!!Icon && showIcon}>
          <Icon className="gh-widget-margin-right-5 gh-widget-tag-icon" />
        </ConditionalRendering>
        <Text className="gh-widget-image-tag-text">{label}</Text>
      </div>
    </div>
  );
};

const ptValue = PropTypes.oneOfType([PropTypes.string, PropTypes.string]);

Tag.propTypes = {
  Icon: PropTypes.elementType,
  label: PropTypes.string,
  className: PropTypes.string,
  leftOffset: ptValue,
  topOffset: ptValue,
  bottomOffset: ptValue,
  rightOffset: ptValue,
  show: PropTypes.bool,
  showIcon: PropTypes.bool,
  tooltipContent: PropTypes.string,
  tooltipId: PropTypes.string,
  onClick: PropTypes.func,
};
Tag.defaultProps = {
  Icon: null,
  className: "",
  leftOffset: "initial",
  topOffset: "initial",
  rightOffset: "initial",
  bottomOffset: "initial",
  show: true,
  showIcon: true,
  label: "",
  tooltipContent: null,
  tooltipId: null,
  onClick: () => {},
};
export default React.memo(Tag);
