/**
 * condStrings - "Conditional Strings" -
 * filter a list of boolean expressions and return string.
 *
 * @param args
 * @returns {string}
 */
export const condStrings = (...args) =>
  args
    .reduce((accumulator, itm) => {
      if (itm) {
        accumulator.push(`${itm}`.trim());
        return accumulator;
      }
      return accumulator;
    }, [])
    .join(" ");

export const getTextWidth = (text = "", font = "normal 16px rubik") => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = font;
  const { width } = context.measureText(text);
  return width;
};

export function getTextWidthOld() {
  const canvas = getTextWidthOld.canvas || (getTextWidthOld.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");

  return function (text, node) {
    const font = getCanvasFontSize(node);
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  };
}

function getCanvasFontSize(el = document.body) {
  const fontWeight = getCssStyle(el, "font-weight") || "normal";
  const fontSize = getCssStyle(el, "font-size") || "16px";
  const fontFamily = getCssStyle(el, "font-family") || "Arial";

  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

function getCssStyle(element, prop) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

export function removeFractionalPart(str) {
  const chunks = str.split(".");

  if (chunks.length > 1) return chunks[0];

  return str;
}
// remove all whitespaces
export const trimAll = (str) => str.split(" ").join("");

export function lettersOnly(str) {
  return str.replace(/[^a-zA-Z]/g, "");
  //or return str.match(/[a-z]/gi).join('');
  //or return str.replace(/[^a-z]/gi,"");
}
