import React, { useState } from "react";
import PropTypes from "prop-types";
import "./TwoColumnsWithForm.scss";
import cs from "classnames";
import ExternalEnquiryForm from "../../../form/ExternalEnquiryForm";
import Tag from "../../../common/Tags/Tag";
import { useWidgetConfig } from "../../../../context/widgetConfigContext";
import Title from "../../../UI/Title";
import ConditionalRendering from "../../../UI/ConditionalRendering";
import { ReactComponent as StartSVG } from "../../../../assets/svg/star.svg";
import formThumbnailPlaceholder from "../../../../assets/img/Thumbnail_leonardo109.jpg";
import { tooltipFeatured, tooltipFeaturedDestination } from "../../../../constants/content";
import useHasFeature from "../../../../hooks/useHasFeature";
import { POWERED_BY_GETHELI } from "../../../../constants/features";
import { DESTINATIONS } from "../../../../constants/api";
import { EVENT_FROM } from "../../../../constants/otherConstants";

const TwoColumnsWithForm = (props) => {
  const {
    children,
    eventThumbnail,
    externalEnquiryFormDefaultLocation,
    imageTagLabel,
    className,
    formTitle,
    ifFormDisabled,
    isEventFeatured,
    tooltipId,
    isMapUsed,
    ...rest
  } = props;
  const {
    globalState: { enquiryForm },
  } = useWidgetConfig();
  const { hasFeature } = useHasFeature(POWERED_BY_GETHELI);
  const [helicopterOptions, setHelicopterOptions] = useState([]);

  const formCurrentStep = enquiryForm?.currentStep;
  const showImage = React.useMemo(
    () =>
      formCurrentStep === 0 ||
      (formCurrentStep === 1 && !helicopterOptions.length) ||
      formCurrentStep === 2 ||
      formCurrentStep === 4,
    [enquiryForm],
  );
  const keepFormViewRef = React.useRef(null);
  const featuredText = {
    [DESTINATIONS]: tooltipFeaturedDestination,
    [EVENT_FROM]: tooltipFeatured,
  };

  React.useEffect(() => {
    if (formCurrentStep === 0 || !formCurrentStep) return;
    keepFormViewRef?.current?.scrollIntoView({ block: "center" });
  }, [formCurrentStep]);

  return (
    <div
      className={cs(
        "gh-widget-d-flex",
        "gh-widget-column-layout-wrapper",
        getStepClassName[formCurrentStep],
        className,
      )}
    >
      <div className="gh-widget-column-layout-first-column">{children}</div>
      <div
        className={cs(
          "gh-widget-column-layout-second-column",
          {
            "gh-widget-column-layout-form-disabled": ifFormDisabled,
          },
          {
            "gh-widget-column-layout-helicopter-options-step": formCurrentStep === 1,
          },
          {
            "--options": formCurrentStep === 1 && helicopterOptions.length,
          },
          {
            "gh-widget-flex-justify-center": formCurrentStep === 4,
          },
        )}
      >
        <ConditionalRendering showComponent={!!formTitle}>
          <Title
            content={formTitle}
            type="standard"
            className="gh-widget-enquiry-form-title"
          />
        </ConditionalRendering>
        <div ref={keepFormViewRef} />
        <ConditionalRendering showComponent={showImage}>
          <div className={cs("gh-widget-column-layout-event-image-wrapper")}>
            <img
              src={eventThumbnail.src}
              alt={imageTagLabel}
              className={cs("gh-widget-column-layout-event-image")}
              style={{
                filter: eventThumbnail.blur ? "blur(10px)" : "none",
                transition: eventThumbnail.blur ? "none" : "filter .4s ease-out",
              }}
            />

            <Tag
              Icon={StartSVG}
              className="gh-widget-card-featured-tag gh-widget-card-featured-tag-inside-event"
              show={isEventFeatured}
              tooltipId={tooltipId}
              tooltipContent={featuredText[props.formType]}
            />
          </div>
        </ConditionalRendering>

        <ExternalEnquiryForm
          className="gh-widget-column-layout-enquiry"
          defaultLocation={externalEnquiryFormDefaultLocation}
          isMapUsed={isMapUsed}
          helicopterOptions={helicopterOptions}
          setHelicopterOptions={setHelicopterOptions}
          {...rest}
        />
      </div>
    </div>
  );
};

TwoColumnsWithForm.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.string]),
  eventThumbnail: PropTypes.shape({ src: PropTypes.string, blur: PropTypes.bool }),
  externalEnquiryFormDefaultLocation: PropTypes.shape({
    dir: PropTypes.oneOf(["from", "to"]),
    location: PropTypes.object,
  }),
  imageTagLabel: PropTypes.string,
  className: PropTypes.string,
  formTitle: PropTypes.string,
  tooltipId: PropTypes.string,
  ifFormDisabled: PropTypes.bool,
  isEventFeatured: PropTypes.bool,
  isMapUsed: PropTypes.bool,
  formType: PropTypes.string.isRequired,
};
TwoColumnsWithForm.defaultProps = {
  externalEnquiryFormDefaultLocation: null,
  imageTagLabel: "On Demand",
  className: "",
  formTitle: null,
  ifFormDisabled: false,
  isEventFeatured: false,
  isMapUsed: false,
  eventThumbnail: { src: formThumbnailPlaceholder, blur: false },
};
export default React.memo(TwoColumnsWithForm);

const getStepClassName = {
  0: "--enquiry-form-step",
  1: "--helicopter-options-step",
  2: "--additional-info-step",
  4: "--confirmation-step",
};
