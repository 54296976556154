import React, { useEffect } from "react";
import "./Layout.scss";
import classNames from "classnames";
import LayoutWidget from "../LayoutWidget";
import LayoutIframe from "../LayoutIframe";
import { axiosSetHeader } from "../../../services/axios/axiosClient";
import useUrlRules from "../../../hooks/widgetConfig/useUrlRules";
import { useWidgetConfig } from "../../../context/widgetConfigContext";
import { TYPE_IFRAME, TYPE_WIDGET } from "../../../constants/auth";
import { useContainerQuery } from "react-container-query";
import { queryBreakpoints } from "../../../constants/uiConfig";
import { navigationTabs } from "../../../constants/features";
import ConditionalRendering from "../../UI/ConditionalRendering";

function Layout() {
  const {
    authData: { app_type, token },
  } = useWidgetConfig();
  const showWidgetUrlRestrictions = useUrlRules();
  const [params, containerRef] = useContainerQuery(queryBreakpoints);
  const {
    setGlobalState,
    widgetConfig: { features },
  } = useWidgetConfig();

  const showWidgetTabsRestrictions = React.useMemo(
    () => navigationTabs.filter((tab) => features.includes(tab))?.length > 0,
    [features, navigationTabs],
  );

  useEffect(() => {
    setGlobalState((prevState) => ({ ...prevState, containerQueryParams: params }));
  }, [params, setGlobalState]);

  useEffect(() => {
    if (token) axiosSetHeader("x-authorization", `Bearer ${token}`);
  }, [token]);

  useEffect(() => {
    if (showWidgetTabsRestrictions) return;
    console.warn("You don't have access to any widget tabs. Please contact the GetHeli team.");
  }, [showWidgetTabsRestrictions]);

  const renderLayout = () => {
    if (app_type === TYPE_WIDGET && showWidgetUrlRestrictions && token) return <LayoutWidget />;
    if (app_type === TYPE_IFRAME && showWidgetUrlRestrictions && token) return <LayoutIframe />;
    return "";
  };

  const isAppIframe = app_type === TYPE_IFRAME;
  const isAppWidget = app_type === TYPE_WIDGET;

  const appWrapperClass = classNames({
    ...params,
    "gh-widget-app-cq": true,
    "gh-widget-app": true,
    "gh-widget-app-type-widget": isAppWidget,
    "gh-widget-app-type-iframe": isAppIframe,
  });

  return (
    <ConditionalRendering showComponent={showWidgetTabsRestrictions}>
      <div
        className={appWrapperClass}
        ref={containerRef}
      >
        <div className={classNames({ "gh-widget-app-blur": isAppIframe })}>{renderLayout()}</div>
      </div>
    </ConditionalRendering>
  );
}

Layout.propTypes = {};
Layout.defaultProps = {};
export default Layout;
