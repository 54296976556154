import { useCallback, useMemo } from "react";
import useWidgetConfigStore from "../store/useWidgetConfigStore";

const useHasFeature = (featureName = null) => {
  const widgetConfig = useWidgetConfigStore((state) => state.widgetConfig);
  const features = useMemo(() => widgetConfig?.features || [], [widgetConfig]);
  const checkFeature = useCallback((feature) => features.includes(feature), [features]);
  const hasFeature = useMemo(() => featureName && checkFeature(featureName), [featureName, checkFeature]);

  return { checkFeature, hasFeature };
};

export default useHasFeature;
