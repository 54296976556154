import React from "react";
import PropTypes from "prop-types";
import IconsMarker from "../IconsMarker";
import { dateFormat } from "../../../utils/timeUtils";

// utils
const secondaryTextConditionsAutocomplete = (secondary_text, start_date, end_date, category) => ({
  events: `Events • ${dateFormat(start_date, "YYYY-MM-DD", "ddd D MMMM, YYYY")} - ${dateFormat(
    end_date,
    "YYYY-MM-DD",
    "ddd D MMMM, YYYY",
  )} • ${secondary_text}`,
  helipads: `${category} • ${secondary_text}`,
});

function AutoCompleteDropdownItem(props) {
  const {
    onClick,
    className,
    place: { location, secondary_text, source, start_date, end_date, category },
    locationType,
  } = props;

  const computedSecondaryText =
    secondaryTextConditionsAutocomplete(secondary_text, start_date, end_date, category)[locationType] || secondary_text;
  return (
    <li
      className={`gh-widget-autocomplete-list-item ${className}`}
      onClick={onClick}
    >
      <div className="gh-widget-autocomplete-list-title-and-icon">
        <IconsMarker
          type={source}
          className="gh-widget-margin-right-15 gh-widget-autocomplete-place-icon"
        />
        <div className="gh-widget-autocomplete-place-title">{location}</div>
      </div>
      <div className="gh-widget-autocomplete-place-location">{computedSecondaryText}</div>
    </li>
  );
}
AutoCompleteDropdownItem.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  place: PropTypes.object,
  locationType: PropTypes.string,
};
AutoCompleteDropdownItem.defaultProps = { className: "" };
export default AutoCompleteDropdownItem;
