import React from "react";
import PropTypes from "prop-types";
import "./DateTimePickerInline.scss";
import { Controller } from "react-hook-form";
import DateTimeComponent from "../../../UI/DateTimeComponent";
import calendar from "../../../../assets/svg/calendar.svg";
import timeConstants from "../../../../constants/timeConstants";
import ErrorMessageComponent from "../../../UI/ErrorMessageComponent";
import cs from "classnames";

const classesForCssIsolation = "gh-date-picker gh-time-picker gh-important";

function DateTimePickerInline(props) {
  const {
    className,
    control,
    clearErrors,
    updateRange,
    errors,
    dateName,
    timeName,
    isDateDisabled,
    isTimeDisabled,
    labelDate,
    labelTime,
    visibility,
    isValidBeforeSpecificDate,
    isValidAfterSpecificDate,
    timeErrorMessage,
    dateErrorMessage,
    pickOnly,
    validateRange,
    dateFormat,
  } = props;

  const timeClassName = React.useMemo(
    () => ({
      [`gh-widget-min-width-${timeName}`]: !!timeName,
    }),
    [timeName],
  );

  if (!visibility) return "";
  return (
    <div className={cs(className, classesForCssIsolation)}>
      <div className="gh-widget-date-time-picker-inline-labels-wrapper">
        <label className="gh-widget-label gh-widget-date-time-picker-inline-label">{labelDate}</label>
        <label className={cs("gh-widget-label gh-widget-date-time-picker-inline-label", timeClassName)}>
          {labelTime}
        </label>
      </div>
      <div className="gh-widget-d-flex">
        <div className="gh-widget-position-relative gh-widget-enquire-calendar-box gh-widget-margin-bottom-5 gh-widget-margin-right-5 gh-widget-date-time-picker-inline-date-wrapper gh-widget-enquire-calendar">
          <Controller
            control={control}
            name={dateName}
            rules={{ required: { value: true, message: dateErrorMessage || "Is required" } }}
            render={({ field: { onChange, value, name } }) => (
              <DateTimeComponent
                name={name}
                onChange={(val) => {
                  clearErrors(name);
                  onChange(val.toString());
                  validateRange();
                }}
                icon={calendar}
                value={value}
                className="gh-widget-old-input gh-widget-calendar-input"
                isValidDate
                isValidDateBefore
                isValidBeforeSpecificDate={isValidBeforeSpecificDate}
                isValidAfterSpecificDate={isValidAfterSpecificDate}
                dateFormat={dateFormat}
                showClearIcon={false}
                attrProps={{ className: "enquire-time-icon" }}
                disabled={isDateDisabled}
                pickOnly={pickOnly}
              />
            )}
          />
        </div>
        <div className={cs("gh-widget-date-time-picker-inline-time-wrapper", timeClassName)}>
          <Controller
            control={control}
            name={timeName}
            rules={{ required: { value: true, message: timeErrorMessage || "Is required" } }}
            render={({ field: { value, name } }) => (
              <DateTimeComponent
                name={name}
                onChange={(val) => {
                  clearErrors(name);
                  clearErrors("return_at");
                  updateRange(name, val);
                  validateRange();
                }}
                value={value}
                className="gh-widget-old-input gh-widget-time-input"
                dateFormat={false}
                timeFormat={timeConstants.timeFormat}
                placeholder="Pick a time"
                showClearIcon={false}
                attrProps={{ className: "enquire-time-icon" }}
                disabled={isTimeDisabled}
                timeConstraints={{ minutes: { step: 5 } }}
              />
            )}
          />
        </div>
      </div>
      <ErrorMessageComponent className="gh-widget-app-date-time-picker-error">
        {errors[dateName]?.message || errors[timeName]?.message}
      </ErrorMessageComponent>
    </div>
  );
}

DateTimePickerInline.propTypes = {
  className: PropTypes.string,
  control: PropTypes.object,
  clearErrors: PropTypes.func,
  updateRange: PropTypes.func,
  errors: PropTypes.object,
  isDateDisabled: PropTypes.bool,
  isTimeDisabled: PropTypes.bool,
  timeName: PropTypes.string.isRequired,
  dateName: PropTypes.string.isRequired,
  labelDate: PropTypes.string.isRequired,
  labelTime: PropTypes.string.isRequired,
  timeErrorMessage: PropTypes.string,
  dateErrorMessage: PropTypes.string,
  visibility: PropTypes.bool,
  pickOnly: PropTypes.bool,
  isValidBeforeSpecificDate: PropTypes.bool,
  isValidAfterSpecificDate: PropTypes.bool,
  validateRange: PropTypes.any,
  dateFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

DateTimePickerInline.defaultProps = {
  isDateDisabled: false,
  isTimeDisabled: false,
  visibility: true,
  labelDate: "",
  labelTime: "",
  timeErrorMessage: null,
  dateErrorMessage: null,
};
export default DateTimePickerInline;
