import React from "react";
import enquiryThumbnailPlaceholder from "../assets/img/Thumbnail_leonardo109.jpg";

// let haveImageBeenCached = false;
const useProgressiveImg = (lowQualitySrc, highQualitySrc, placeholderSrc = null) => {
  let isComponentUnmounted = false;
  const [src, setSrc] = React.useState(lowQualitySrc);
  const blur = React.useMemo(() => src === lowQualitySrc, [lowQualitySrc, src]);

  React.useEffect(() => {
    setSrc(lowQualitySrc);
    const img = new Image();
    img.src = highQualitySrc;
    img.onload = () => {
      if (isComponentUnmounted) return;
      setSrc(highQualitySrc);
      // haveImageBeenCached = true;
    };

    return () => {
      img.onload = () => {};
      isComponentUnmounted = true;
    };
  }, [lowQualitySrc, highQualitySrc]);

  if (!highQualitySrc) {
    return [placeholderSrc ?? enquiryThumbnailPlaceholder, { blur: false }];
  }
  // if (haveImageBeenCached) {
  //   return [src, { blur: false }];
  // }
  return [src, { blur }];
};
export default useProgressiveImg;
