import React from "react";
import PropTypes from "prop-types";
import "./Text.scss";
import cs from "classnames";

const Text = ({ children, className, show }) => {
  if (!show) return null;
  return <p className={cs("gh-widget-app-text", className)}>{children}</p>;
};

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string]),
  className: PropTypes.string,
  show: PropTypes.bool,
};
Text.defaultProps = { children: "", className: "", show: true };
export default Text;
