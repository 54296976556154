import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./input.scss";

function Input(props) {
  const { className, placeholder, onChange, value, onFocus, onKeyDown, id, ...rest } = props;

  const [focus, setFocus] = useState(false);
  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);
  useEffect(() => {
    if (onFocus) onFocus(focus);
  }, [focus]);
  return (
    <input
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      className={`${className} gh-widget-input-component`}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={onKeyDown}
      id={id}
      {...rest}
    />
  );
}
Input.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  id: PropTypes.string,
};
Input.defaultProps = { className: "", placeholder: "", onFocus: null, onKeyDown: () => {} };
export default Input;
