import React from "react";
import PropTypes from "prop-types";
import "./EventsContentDynamic.scss";
import { eventDescription, helicoptersTitle, landingInformation } from "../../../../constants/content";
import Button from "../../../UI/Button";
import ConditionalRendering from "../../../UI/ConditionalRendering";
import { getOuterHeight } from "../../../../utils/renderUtils";
import cs from "classnames";
import { useWidgetConfig } from "../../../../context/widgetConfigContext";

const EventsContentDynamic = (props) => {
  const { eventDetails } = props;
  const {
    description,
    btn_label,
    btn_url,
    description_heading,
    helicopter_relevance_description,
    helicopter_relevance_btn_label,
    helicopter_relevance_btn_url,
    landing_information_content,
    landing_information_btn_label,
    landing_information_btn_url,
    helicopter_relevance_heading,
    landing_information_heading,
    event_introduction,
  } = eventDetails;
  const dynamicContentRef = React.useRef(null);
  const [showMore, setShowMore] = React.useState(false);
  const [showExpandContentButton, setShowExpandContentButton] = React.useState(false);
  const {
    globalState: { containerQueryParams },
  } = useWidgetConfig();

  React.useEffect(() => {
    if (!dynamicContentRef.current) return;
    // dynamic content height calculation
    // there is parsed height of dynamic content child and grandchild
    // and the values is compared, assigned the highest value to dynamicContentHeight
    // --- !!! There is a bug with the height of the dynamic content child ---
    // --- for this reason I'm checking grandchild height                  ---
    let contentHeight = 0;
    for (let child of dynamicContentRef.current.children) {
      let contentHeightWithGrandChildren = 0;
      for (let grandChild of child.children) {
        contentHeightWithGrandChildren += getOuterHeight(grandChild);
      }
      const childHeight = getOuterHeight(child);
      contentHeight += contentHeightWithGrandChildren > childHeight ? contentHeightWithGrandChildren : childHeight;
    }
    setShowExpandContentButton(contentHeight > 200);
  }, [dynamicContentRef]);

  const isMaxWidth900 = React.useMemo(
    () => containerQueryParams && containerQueryParams["gh-widget-container-query-max-900"],
    [containerQueryParams],
  );
  const handleShowMore = React.useCallback(() => setShowMore((prevState) => !prevState), []);
  const extentContentBtnLabel = React.useMemo(() => (showMore ? "Show Less" : "Show More"), [showMore]);

  return (
    <>
      <div className={cs("gh-widget-event-dynamic-content", { truncate: !showMore && isMaxWidth900 })}>
        <div
          className="gh-widget-event-dynamic-content-inner-wrapper"
          ref={dynamicContentRef}
        >
          <ConditionalRendering showComponent={!!event_introduction}>
            <div
              className="gh-widget-margin-bottom-10"
              dangerouslySetInnerHTML={{
                __html: event_introduction,
              }}
            />
          </ConditionalRendering>
          <h2
            className="gh-widget-events-dynamic-content-heading"
            dangerouslySetInnerHTML={{
              __html: description_heading || eventDescription,
            }}
          />
          <div
            className="gh-widget-events-dynamic-content-text"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <Button
            show={description && btn_label !== null}
            actionType="link"
            variant="outlined"
            className="gh-widget-events-dynamic-content-buttons"
            target="_blank"
            rel="noopener noreferrer"
            href={btn_url}
          >
            {btn_label}
          </Button>

          <ConditionalRendering showComponent={helicopter_relevance_description !== null}>
            <h2
              className="gh-widget-events-dynamic-content-heading"
              dangerouslySetInnerHTML={{
                __html: helicopter_relevance_heading || helicoptersTitle,
              }}
            />
            <div
              className="gh-widget-events-dynamic-content-text"
              dangerouslySetInnerHTML={{
                __html: helicopter_relevance_description,
              }}
            />
            <Button
              show={!!helicopter_relevance_btn_label}
              actionType="link"
              variant="outlined"
              className="gh-widget-events-dynamic-content-buttons"
              target="_blank"
              rel="noopener noreferrer"
              href={helicopter_relevance_btn_url}
            >
              {helicopter_relevance_btn_label}
            </Button>
          </ConditionalRendering>

          <ConditionalRendering showComponent={!!landing_information_content}>
            <h2
              className="gh-widget-events-dynamic-content-heading"
              dangerouslySetInnerHTML={{
                __html: landing_information_heading || landingInformation,
              }}
            />
            <div
              className="gh-widget-events-dynamic-content-text"
              dangerouslySetInnerHTML={{
                __html: landing_information_content,
              }}
            />
            <Button
              show={!!landing_information_btn_label}
              actionType="link"
              variant="outlined"
              className="gh-widget-events-dynamic-content-buttons"
              target="_blank"
              rel="noopener noreferrer"
              href={landing_information_btn_url}
            >
              {landing_information_btn_label}
            </Button>
          </ConditionalRendering>
        </div>
      </div>

      <Button
        variant="text"
        color="primary"
        className="gh-dynamic-content-extent-content-btn"
        onClick={handleShowMore}
        show={showExpandContentButton && isMaxWidth900}
      >
        {extentContentBtnLabel}
      </Button>
    </>
  );
};

EventsContentDynamic.propTypes = { eventDetails: PropTypes.object };
EventsContentDynamic.defaultProps = {};
export default React.memo(EventsContentDynamic);
