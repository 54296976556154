import React from "react";
import PropTypes from "prop-types";
import { pickSeason, tooltipFeaturedDestination } from "../../../../constants/content";
import EventsContentDynamic from "../EventsContentDynamic";
import TwoColumnsWithForm from "../../../layouts/WindowLayouts/TwoColumnsWithForm";
import eventThumbnailPlaceholder from "../../../../assets/img/eventThumbnailMin.png";
import cs from "classnames";
import ButtonBack from "../../../UI/ButtonBack";
import Tag from "../../../common/Tags/Tag";
import { ReactComponent as StartSVG } from "../../../../assets/svg/star.svg";
import useProgressiveImg from "../../../../hooks/useProgressiveImg";
import useHasFeature from "../../../../hooks/useHasFeature";
import { lettersOnly } from "../../../../utils/stringUtils";
import { POWERED_BY_GETHELI } from "../../../../constants/features";
import { DESTINATIONS } from "../../../../constants/api";
import Title from "../../../UI/Title";
import LineSeparator from "../../../UI/LineSeparator";
import EventContentInfoRow from "../EventContentInfoRow";

const imageTagLabel = "Destination";
const DestinationContent = (props) => {
  const { eventDetails, handleModalClose } = props;
  const { name, locations_string_all, start_date, end_date, start_month, end_month, id: eventID } = eventDetails;
  const { hasFeature } = useHasFeature(POWERED_BY_GETHELI);
  if (!eventDetails.event_location) {
    eventDetails.event_location = {};
  }
  const eventImage = eventDetails?.photo;

  const isEventFeatured = React.useMemo(() => !!eventDetails?.is_featured_destination, [eventDetails]);
  const tooltipId = React.useMemo(() => lettersOnly(`${name}_${start_date}_${end_date}`), [name, end_date, start_date]);

  const lowQualityImage = eventImage && eventImage + "?width=10";
  const highQualityImage = eventImage && eventImage + "?width=435";
  const [src, { blur }] = useProgressiveImg(lowQualityImage, highQualityImage, eventThumbnailPlaceholder);
  const eventImageProcessed = React.useMemo(() => ({ src, blur }), [blur, src]);

  // when open an event scroll to top of widget
  React.useEffect(() => {
    const widgetWrapper = document.querySelector(".gh-widget-main-wrapper");
    if (!widgetWrapper) return;
    widgetWrapper.scrollIntoView({ block: "start" });
  }, []);

  return (
    <div className={cs("gh-widget-event-content-container")}>
      <ButtonBack
        onClick={handleModalClose}
        label="Back to Destinations"
        className="gh-widget-event-back-btn"
      />
      <div className="gh-widget-content-layout-wrapper">
        {/* todo: wrap in a component */}
        <div
          className={cs(
            "gh-widget-column-layout-event-image-wrapper",
            "gh-widget-column-layout-event-image-wrapper-changed-place",
          )}
        >
          <img
            src={src}
            alt="Event"
            className={cs("gh-widget-column-layout-event-image")}
            style={{
              filter: blur ? "blur(10px)" : "none",
              transition: blur ? "none" : "filter .4s ease-out",
            }}
          />
          {/*<Tag*/}
          {/*  label={imageTagLabel}*/}
          {/*  Icon={GetheliSmallLogo}*/}
          {/*  showIcon={hasFeature}*/}
          {/*  className="gh-widget-card-event-tag"*/}
          {/*/>*/}
          <Tag
            Icon={StartSVG}
            className="gh-widget-card-featured-tag gh-widget-card-featured-tag-inside-event"
            show={isEventFeatured}
            tooltipId={tooltipId}
            tooltipContent={tooltipFeaturedDestination}
          />
        </div>
        {/*======================*/}
        <TwoColumnsWithForm
          eventThumbnail={eventImageProcessed}
          imageTagLabel={imageTagLabel}
          isEventFeatured={isEventFeatured}
          tooltipId={tooltipId}
          isMapUsed={false}
          formType={DESTINATIONS}
          eventID={eventID}
        >
          <div className="gh-widget-event-content-wrapper">
            <Title
              content={name}
              type="standard"
              className="gh-widget-events-title-main"
            />
            <Title
              content={locations_string_all}
              type="medium"
              className="gh-widget-events-title-location"
            />

            {start_month ? (
              <EventContentInfoRow
                title={pickSeason}
                value={`${start_month} ${end_month ? "to " + end_month : ""}`}
              />
            ) : (
              ""
            )}

            <LineSeparator className="gh-widget-margin-bottom-25" />
            <EventsContentDynamic eventDetails={eventDetails} />
          </div>
        </TwoColumnsWithForm>
      </div>
    </div>
  );
};

DestinationContent.propTypes = { eventDetails: PropTypes.object, handleModalClose: PropTypes.func };
DestinationContent.defaultProps = {};
export default DestinationContent;
