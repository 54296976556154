import React from "react";
// import PropTypes from "prop-types";
import "./LayoutIframe.scss";
import ExternalEnquiryForm from "../../form/ExternalEnquiryForm";
import LayoutComplexWidget from "../LayoutComplexWidget";

function LayoutIframe() {
  // return <ExternalEnquiryForm />;
  return <LayoutComplexWidget />;
}

LayoutIframe.propTypes = {};
LayoutIframe.defaultProps = {};
export default LayoutIframe;
