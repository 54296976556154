import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

const Tab = ({ children, className, hide }) => {
  if (hide) return;
  return <div className={cs(className, "gh-widget-tab-content-wrapper")}>{children}</div>;
};

Tab.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  hide: PropTypes.bool,
};
Tab.defaultProps = {
  className: "",
  hide: false,
};

export default Tab;
