import eventSecondary from "../../../assets/svg/eventSecondary.svg";
import hotelSecondary from "../../../assets/svg/hotelSecondary.svg";
import restaurantSecondary from "../../../assets/svg/restaurantSecondary.svg";
import landingSecondary from "../../../assets/svg/landingSecondary.svg";
import planeSecondary from "../../../assets/svg/planeSecondary.svg";
import apoiSecondary from "../../../assets/svg/apoiSecondary.svg";
import eventPrimary from "../../../assets/svg/eventPrimary.svg";
import hotelPrimary from "../../../assets/svg/hotelPrimary.svg";
import restaurantPrimary from "../../../assets/svg/restaurantPrimary.svg";
import landingPrimary from "../../../assets/svg/landingPrimary.svg";
import planePrimary from "../../../assets/svg/planePrimary.svg";
import apoiPrimary from "../../../assets/svg/apoiPrimary.svg";
import eventIcon from "../../../assets/svg/eventIcon.svg";
import hotelIcon from "../../../assets/svg/hotelIcon.svg";
import cutleryIcon from "../../../assets/svg/cutleryIcon.svg";
import landingSiteIcon from "../../../assets/svg/landingSiteIcon.svg";
import planeIcon from "../../../assets/svg/planeIcon.svg";
import apoiIcon from "../../../assets/svg/apoiIcon.svg";

export const offset = 0.52990619827;

export const LondonLocation = { lat: 51.509865, lng: -0.118092 };

export function secondaryIcon(type) {
  switch (type) {
    case "Event":
    case "gh_event":
      return eventSecondary;
    case "Hotel":
      return hotelSecondary;
    case "Restaurant":
      return restaurantSecondary;
    case "Landing Site":
      return landingSecondary;
    case "Aerodrome":
      return planeSecondary;
    case "Aerial Point of Interest":
      return apoiSecondary;
    default:
      return apoiSecondary;
  }
}

export function primaryIcon(type) {
  switch (type) {
    case "Event":
    case "gh_event":
      return eventPrimary;
    case "Hotel":
      return hotelPrimary;
    case "Restaurant":
      return restaurantPrimary;
    case "Landing Site":
      return landingPrimary;
    case "Aerodrome":
      return planePrimary;
    case "Aerial Point of Interest":
      return apoiPrimary;
    default:
      return apoiPrimary;
  }
}

export function placeTypeIcon(type) {
  switch (type) {
    case "Event":
    case "gh_event":
      return eventIcon;
    case "Hotel":
      return hotelIcon;
    case "Restaurant":
      return cutleryIcon;
    case "Landing Site":
      return landingSiteIcon;
    case "Aerodrome":
      return planeIcon;
    case "Aerial Point of Interest":
      return apoiIcon;
    default:
      return apoiIcon;
  }
}

export const mapStyles = [
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.attraction",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];
