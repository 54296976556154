// comments from Sergiu. I've added this file to git ignore, because I want to edit the Base Url on the server, as on different servers there are different base urls
export const API_BASE_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}/`;
export const API_HEADERS = {
  Accept: "application/json, */*",
  "Content-Type": "application/json",
};
export const API_TIMEOUT = 5000;

export const GET = "GET";
export const POST = "POST";

// API constants
export const PUBLIC_EVENTS = "public_events";
export const DESTINATIONS = "destinations";
export const GET_HELI_FURTHER_SECOND_STEP = "get-heli-further-second-step";
