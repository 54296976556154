import React from "react";
import "./OnDemand.scss";
import TwoColumnsWithForm from "../../../layouts/WindowLayouts/TwoColumnsWithForm";
import OnDemandContent from "./OnDemandContent";
import { ON_DEMAND_FROM } from "../../../../constants/otherConstants";
import useOnDemand from "./  useOnDemand";
import cs from "classnames";

const OnDemand = () => {
  const { onDemandDefaultLocation } = useOnDemand();

  return (
    <TwoColumnsWithForm
      className={cs("gh-on-demand-layout", getAdditionalClassName(onDemandDefaultLocation))}
      isMapUsed={true}
      formType={ON_DEMAND_FROM}
      externalEnquiryFormDefaultLocation={onDemandDefaultLocation}
    >
      <OnDemandContent />
    </TwoColumnsWithForm>
  );
};

OnDemand.propTypes = {};
OnDemand.defaultProps = {};
export default OnDemand;

const getAdditionalClassName = (onDemandDefaultLocation) => {
  const direction = onDemandDefaultLocation?.dir;
  const directions = {
    from: "gh-on-demand-layout-from-default-location",
    to: "gh-on-demand-layout-to-default-location",
  };
  if (!direction) return "";
  return directions[direction];
};
