import React, { useState } from "react";
import "./EventsWindow.scss";
import Pagination from "../../../UI/Pagination";
import Card from "../../../UI/Card";
import useDimension from "../../../../hooks/useDimension";
import { getRequest } from "../../../../services/axios/axiosClient";
import Loader from "../../../UI/Loader";
import cs from "classnames";
import ConditionalRendering from "../../../UI/ConditionalRendering";
import { trimAll } from "../../../../utils/stringUtils";
import { useWidgetConfig } from "../../../../context/widgetConfigContext";
import EventsFilter from "./EventsFilter";
import PropTypes from "prop-types";
import { tooltipFeatured, tooltipFeaturedDestination } from "../../../../constants/content";
import { PUBLIC_EVENTS } from "../../../../constants/api";
import usePrevious from "../../../../hooks/usePrevious";

const EventsWindow = ({ children, endpoint }) => {
  const {
    globalState: { containerQueryParams },
  } = useWidgetConfig();

  const pageSize = React.useMemo(
    () => (containerQueryParams && containerQueryParams["gh-widget-container-query-max-479"] ? 8 : 12),
    [containerQueryParams["gh-widget-container-query-max-479"]],
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isOpenEventModal, setIsOpenEventModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsApiRes, setEventsApiRes] = useState(null);
  const [eventsFiltersLabels, setEventsFiltersLabels] = React.useState({});
  const [checkedFilters, setCheckedFilters] = React.useState({ countries: [], type: [] });
  const previousCheckedFilters = usePrevious(checkedFilters);

  const [paginationRef] = useDimension();
  const eventsWrapperRef = React.useRef();

  const eventsData = React.useMemo(() => eventsApiRes?.data.data ?? [], [eventsApiRes]);
  const eventsTotalNumber = React.useMemo(() => eventsApiRes?.data.meta.pagination.total ?? 12, [eventsApiRes]);
  const eventsFiltersLabelsMemoized = React.useMemo(() => eventsFiltersLabels, [eventsFiltersLabels]);

  React.useEffect(() => {
    getEventsCallback(1).then((res) => {
      setEventsFiltersLabels({ countries: res.data.countries, type: res.data.types });
    });

    return () => {
      setEventsApiRes(null);
      setEventsFiltersLabels({});
    };
  }, []);

  React.useEffect(() => {
    if (checkedFilters.type.length || checkedFilters.countries.length) {
      getEventsCallback(1);
      setCurrentPage(1);
    } else if (
      (previousCheckedFilters && previousCheckedFilters?.type.length) ||
      previousCheckedFilters?.countries.length
    ) {
      getEventsCallback(1);
      setCurrentPage(1);
    }
  }, [checkedFilters]);

  React.useEffect(() => {
    if (currentPage !== 1) eventsWrapperRef?.current?.scrollIntoView();
  }, [currentPage]);

  const getEventsCallback = (page) => {
    setIsLoading(true);

    const filters = generateFiltersQuery(checkedFilters);

    return getRequest(`${endpoint}?page=${page}&limit=${pageSize}&event_type=external${filters}`).then((res) => {
      setEventsApiRes(res);
      setIsLoading(false);
      return res;
    });
  };

  const handleModalOpen = (index, eventDetails) => {
    setIsOpenEventModal(true);
    setModalContent(eventDetails);
  };
  const onPageChange = (page) => {
    setCurrentPage(page);
    getEventsCallback(page);
  };
  const handleModalClose = React.useCallback(() => setIsOpenEventModal(false), []);

  return (
    <>
      <div
        className="gh-widget-events-wrapper"
        ref={eventsWrapperRef}
      >
        {!isOpenEventModal && (
          <EventsFilter
            checkedFilters={checkedFilters}
            setCheckedFilters={setCheckedFilters}
            eventsFiltersLabelsMemoized={eventsFiltersLabelsMemoized}
            titles={getTitles(endpoint)}
          />
        )}
        <Loader
          className="gh-widget-events-loader"
          loading={isLoading}
        />
        <ConditionalRendering showComponent={!isLoading && !isOpenEventModal}>
          <div className={cs("gh-widget-events-grid", { "gh-widget-events-grid-loading": isLoading })}>
            {eventsData?.map((eventDetails, index) => (
              <Card
                onClick={() => handleModalOpen(index, eventDetails)}
                title={eventDetails.name}
                image={eventDetails.thumbnail ?? eventDetails.photo}
                type={eventDetails.type}
                location={eventDetails.country}
                key={index + trimAll(eventDetails.name)}
                isFeaturedEvent={
                  eventDetails.is_featured_event
                    ? tooltipFeatured
                    : eventDetails.is_featured_destination
                    ? tooltipFeaturedDestination
                    : false
                }
                eventDetails={eventDetails}
              />
            ))}
          </div>
        </ConditionalRendering>
        <ConditionalRendering showComponent={isOpenEventModal}>
          {React.cloneElement(children, { handleModalClose, eventDetails: modalContent })}
        </ConditionalRendering>
      </div>

      <ConditionalRendering showComponent={!isOpenEventModal}>
        <Pagination
          className="gh-widget-pagination-bar"
          currentPage={currentPage}
          totalCount={eventsTotalNumber}
          pageSize={pageSize}
          onPageChange={onPageChange}
          prefix="Page"
          paginationRef={paginationRef}
          style={{
            transform: `translateX(-50%)`,
          }}
        />
      </ConditionalRendering>
    </>
  );
};

EventsWindow.propTypes = { endpoint: PropTypes.string.isRequired, children: PropTypes.node.isRequired };
EventsWindow.defaultProps = {};
export default React.memo(EventsWindow);

const generateFiltersQuery = (checkedFilters) => {
  const { countries, type } = checkedFilters;
  let query = "";
  if (countries.length) {
    query += `&location=${countries.join("|")}`;
  }
  if (type.length) {
    query += `&type=${encodeURIComponent(type.join("|"))}`;
  }
  return query;
};

const getTitles = (endpoint) => {
  let titles = {
    countries: "COUNTRY",
    type: "FLIGHT TYPE",
  };

  if (endpoint === PUBLIC_EVENTS) {
    titles = {
      countries: "EVENT LOCATION",
      type: "EVENT TYPE",
    };
  }

  return titles;
};
