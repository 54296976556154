import moment from "moment";
import { ROUND_TRIP } from "../constants/otherConstants";

export function utcTime(time, dropFromFormat, newFormat = "HH:mm") {
  if (time) {
    const utcHours = moment().utcOffset() / 60;
    if (utcHours > 0) {
      return `${moment(time, dropFromFormat).add(utcHours, "hours").format(newFormat)} (UTC${moment().format("Z")})`;
    }
    return `${moment(time, dropFromFormat).subtract(utcHours, "hours").format(newFormat)} (UTC${moment().format("Z")})`;
  }
  return ` (UTC${moment().format("Z")})`;
}

export const formattedTime = (m) => {
  if (m <= 59) return `${m} minutes`;
  if (m > 59) {
    const min = m % 60;
    const h = (m - min) / 60;
    const st = h === 1 ? " hour " : " hours ";
    return `${h + st + min} minutes`;
  }
};

export const dateFormat = (date, format, formatToApply = "D MMMM, YYYY") => {
  return moment(date, format).format(formatToApply);
};

export const returnDateAndTimeFormatForApiCall = (returnDate, returnTime, typeOfTrip) => {
  const [ret_hrs, ret_mins] = returnTime?.split(":") || [0, 0];
  return typeOfTrip === ROUND_TRIP
    ? moment(returnDate).add(Number(ret_hrs), "hours").add(Number(ret_mins), "minutes").format("YYYY-MM-DD HH:mm:ss")
    : null;
};

export const departureDateAndTimeFormatForApiCall = (departureDate, departureTime) => {
  const [dep_hrs, dep_mins] = departureTime.split(":");
  return moment(departureDate)
    .add(Number(dep_hrs), "hours")
    .add(Number(dep_mins), "minutes")
    .format("YYYY-MM-DD HH:mm:ss");
};

export const dateCompactFormat = (date, inputDateFormat = "DD-MM-YYYY", outputFormat = "D MMM, YYYY") => {
  return moment(date, inputDateFormat).format(outputFormat);
};

const parseHoursAndMinutes = (time) => {
  if (moment(time).isValid()) {
    return moment(time).format("HH:mm").split(":");
  }

  return time.split(":");
};

export const transformDateTimeToISO = (date = "", time = "", format = "") => {
  const datetime = moment(date, format);

  if (datetime.isValid()) {
    if (time && Array.isArray(time.split(":"))) {
      const [h, m] = parseHoursAndMinutes(time).map(Number);

      datetime.set("hours", h);
      datetime.set("minutes", m);
    }

    return datetime.utc().toISOString();
  }

  return "";
};
