import React from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import WidgetConfigProvider from "../../../context";
import Layout from "../../layouts/Layout";
import "react-toastify/dist/ReactToastify.css";
import TooltipsDefine from "../../Views/TooltipsDefine";
import useWidgetConfigStore from "../../../store/useWidgetConfigStore";
import { brokersHotFixes } from "../../../utils/brokerHotFixes";
import ReactGA from "react-ga4";

function App({ config }) {
  const setWidgetConfig = useWidgetConfigStore((state) => state.setWidgetConfig);

  React.useEffect(() => {
    const gToken = config?.ga?.token;
    if (gToken) {
      let tokens = gToken;
      // handle multiple tokens
      if (gToken?.split(";")?.length > 1) {
        tokens = tokens.split(";").map((token) => ({ trackingId: token }));
      }
      ReactGA.initialize(tokens);
    }
  }, []);

  React.useEffect(() => {
    setWidgetConfig(config);
    brokersHotFixes();
  }, [config]);

  return (
    <>
      <WidgetConfigProvider config={config}>
        <Layout />
        <TooltipsDefine />
      </WidgetConfigProvider>
      <ToastContainer />
    </>
  );
}

App.propTypes = {
  config: PropTypes.object,
};

export default App;
