import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import PropTypes from "prop-types";
import useEmblaCarousel from "embla-carousel-react";
import "./Slider.css";

import arrow from "../../../assets/svg/sliderArrow.svg";
import oval from "../../../assets/svg/ovalSmall.svg";
import ovalEmpty from "../../../assets/svg/ovalEmptySmall.svg";

const Slider = forwardRef((props, ref) => {
  const {
    imagesUrl,
    children,
    changeExternalIndex,
    className,
    options,
    selectedImg,
    getSelectedImg = () => {},
    autoPlay,
  } = props;

  // if (imagesUrl && children) return "";
  useEffect(() => {}, [imagesUrl]);
  const [viewportRef, embla] = useEmblaCarousel(options || { skipSnaps: false, align: "start" });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const intervalRef = useRef(null);
  const AUTOPLAY_INTERVAL = typeof autoPlay === "number" ? autoPlay : 3000;

  const [isTabActive, setIsTabActive] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (embla) {
      // Start the auto-change when the carousel is ready
      startAutoChange();
    }
  }, [embla]);

  const startAutoChange = () => {
    if (!autoPlay) return;
    // Clear any existing interval
    stopAutoChange();

    // Start a new interval to change slides
    intervalRef.current = setInterval(() => {
      // embla.next();
      embla.scrollNext();
    }, AUTOPLAY_INTERVAL);
  };

  const stopAutoChange = () => {
    if (!autoPlay) return;
    // Clear the interval if it exists
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if (isTabActive) {
      startAutoChange();
    } else {
      stopAutoChange();
    }
  }, [isTabActive]);

  const scrollPrev = useCallback(
    (e) => {
      e.preventDefault();

      if (embla) {
        embla.scrollPrev();
      }
    },
    [embla],
  );

  const scrollNext = useCallback(
    (e) => {
      e.preventDefault();
      if (embla) {
        embla.scrollNext();
      }
    },
    [embla],
  );
  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla]);

  useImperativeHandle(ref, () => ({
    scrollTo(index) {
      scrollTo(index);
    },
    getIndex() {
      return embla.selectedScrollSnap();
    },
  }));

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
    if (changeExternalIndex) changeExternalIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    scrollTo(selectedImg);
  }, [selectedImg]);

  useEffect(() => {
    getSelectedImg(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
    embla.on("reInit", () => {
      onSelect();
      setScrollSnaps(embla.scrollSnapList());
    });
  }, [embla, setScrollSnaps, onSelect]);

  useEffect(() => {
    if (embla) {
      embla.reInit({ skipSnaps: false });
    }
  }, [children, imagesUrl]);

  const renderChildren = useCallback(
    () =>
      children.map((child, index) => (
        <div
          className="embla__slide"
          key={index}
        >
          <div className="embla__slide__inner">
            <div className="embla__slide__item">{child}</div>
          </div>
        </div>
      )),
    [embla, children],
  );
  const renderImages = useCallback(
    () =>
      imagesUrl &&
      imagesUrl.length > 0 &&
      [...imagesUrl].map((img, index) => (
        <div
          className="embla__slide"
          key={index}
        >
          <div className="embla__slide__inner">
            <img
              className="embla__slide__img"
              src={img}
              alt="A cool cat."
            />
          </div>
        </div>
      )),
    [embla, imagesUrl],
  );
  return (
    <React.Fragment>
      <div className={`embla ${className}`}>
        <div
          onMouseEnter={() => {
            stopAutoChange();
          }}
          onMouseLeave={() => {
            startAutoChange();
          }}
          className="embla__viewport"
          ref={viewportRef}
        >
          <div className="embla__container">{children && children.length > 0 ? renderChildren() : renderImages()}</div>
        </div>
        <PrevButton
          onClick={scrollPrev}
          enabled={prevBtnEnabled}
        />
        <NextButton
          onClick={scrollNext}
          enabled={nextBtnEnabled}
        />

        <div className="embla__dots">
          {scrollSnaps.length > 1 &&
            scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
              />
            ))}
        </div>
      </div>
    </React.Fragment>
  );
});

Slider.displayName = "Slider";
Slider.propTypes = {
  imagesUrl: PropTypes.arrayOf(PropTypes.string),
  changeExternalIndex: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.bool]),
  options: PropTypes.object,
  selectedImg: PropTypes.number,
  getSelectedImg: PropTypes.func,
  autoPlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

/*===========Helping components===========*/
// eslint-disable-next-line react/no-multi-comp
const DotButton = ({ selected, onClick }) => (
  <button
    className={`embla__dot ${selected ? "is-selected" : ""}`}
    type="button"
    onClick={onClick}
  >
    <img
      src={selected ? oval : ovalEmpty}
      alt="oval"
    />
  </button>
);

DotButton.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};
// eslint-disable-next-line react/no-multi-comp
const PrevButton = ({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--prev"
    onClick={onClick}
    disabled={!enabled}
  >
    <img
      src={arrow}
      alt="arrow left"
      className="embla__button__svg embla__button__svg-left"
    />
  </button>
);
PrevButton.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
};
// eslint-disable-next-line react/no-multi-comp
const NextButton = ({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--next"
    onClick={onClick}
    disabled={!enabled}
  >
    <img
      src={arrow}
      alt="arrow right"
      className="embla__button__svg"
    />
  </button>
);

NextButton.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Slider;
