import React from "react";
import ReactDOM from "react-dom";
import "./assets/styles/index.scss";
import App from "./components/common/App/App";
import { axiosSetHeader, getRequest } from "./services/axios/axiosClient";
import { TYPE_IFRAME, TYPE_WIDGET } from "./constants/auth";
import { getWidgetToken, loadIframeScript, loadWidgetScript } from "./utils/loadWidgetScript";
import { loadGoogleMapsApi } from "./utils/otherUtils";

// Loading CSS
loadWidgetScript();
// Get token
const widgetToken = getWidgetToken();
// Get token from URL, used on localhost only
const params = new URLSearchParams(window.location?.search);
const iframeToken = params.get("token");
const shouldInjectIframe = params.get("i") === "inject";
// Attach token to Axios Instance
axiosSetHeader("x-authorization", `Bearer ${widgetToken || iframeToken}`);

// Get App config
getRequest("get-external-configuration").then(({ data }) => {
  const baseElement = `getheli-${data.app_type === TYPE_WIDGET ? "widget" : "iframe"}-base-element`;
  //check if React app is already mounted
  if (document.getElementById(baseElement)?.hasChildNodes()) {
    throw new Error("Widget already loaded");
  }

  if (!document.getElementById(baseElement) && (data.app_type === TYPE_WIDGET || shouldInjectIframe)) {
    const newRootDiv = document.createElement("div");
    newRootDiv.setAttribute("id", baseElement);
    document.body.appendChild(newRootDiv);
  }
  // render DOM with config
  const renderDOM = () => {
    ReactDOM.render(
      <App config={{ ...data, token: widgetToken || iframeToken }} />,
      document.getElementById(baseElement),
    );
  };

  // Location Observer to watch location change
  const observer = new MutationObserver((mutations, obs) => loadIframeScript(renderDOM, obs));

  if (data.app_type === TYPE_IFRAME) {
    // Execute on page first load
    loadIframeScript(renderDOM);
    // Add observer
    observer.observe(document, {
      childList: true,
      subtree: true,
    });
  } else if (data.app_type === TYPE_WIDGET) {
    renderDOM();
  }

  loadGoogleMapsApi();
});
