import React from "react";
import PropTypes from "prop-types";
import "./IconsMarker.scss";
import { ReactComponent as YellowMarker } from "../../../assets/svg/yellowMarker.svg";
import { ReactComponent as GreenMarker } from "../../../assets/svg/greenMarker.svg";
import { ReactComponent as OvalMarker } from "../../../assets/svg/oval.svg";

export const icons = {
  gh_event: (props) => <YellowMarker {...props} />,
  hellipaddy: (props) => <GreenMarker {...props} />,
  google: (props) => <OvalMarker {...props} />,
};

function IconsMarker(props) {
  const { className, type } = props;
  const Component = icons[type];
  return (
    <div className={className}>
      <Component />
    </div>
  );
}
IconsMarker.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["gh_event", "hellipaddy", "google"]).isRequired,
};
IconsMarker.defaultProps = { className: "" };
export default IconsMarker;
