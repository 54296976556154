import React from "react";
import PropTypes from "prop-types";

const AutocompleteReadOnly = (props) => {
  const { label, readOnlyData } = props;
  return (
    <div className="gh-widget-autocomplete-readonly">
      <div className={`gh-widget-autocomplete-wrapper`}>
        <p className="gh-widget-label">{label}</p>
        <div className="gh-widget-autocomplete-fake-input-wrapper">
          <div className="gh-widget-autocomplete-fake-input">{readOnlyData.value}</div>
          <div
            className="gh-widget-autocomplete-readonly-secondary-text-wrapper"
            style={{
              width: "45%",
              display: "flex",
            }}
          >
            <div className="gh-widget-autocomplete-input-additional-info">{readOnlyData.selectedPlaceInfo}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
AutocompleteReadOnly.propTypes = { label: PropTypes.string, readOnlyData: PropTypes.object };
export default AutocompleteReadOnly;
