import { createContext, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { initialMapCoordinates } from "../constants/mapConfig";
import { TYPE_WIDGET } from "../constants/auth";

const initialAuthState = {
  token: "",
  app_type: "",
};
const initialGlobalState = {
  tooltips: {
    helicoptersList: [],
    tags: [],
  },
  modalsConfig: {
    eventsModal: {
      isOpen: false,
    },
  },
  captcha: {
    captchaToken: process.env.REACT_APP_RECAPTCHA_WEB_SITE_KEY,
  },
  containerQueryParams: null,
};

const initialData = {
  appearance: {},
  features: [],
};

const WidgetConfigContext = createContext(null);

// wrapper for the provider
export function WidgetConfigProvider({ children, config }) {
  const [authData, setAuthData] = useState(initialAuthState);
  const [globalState, setGlobalState] = useState(initialGlobalState);
  const [widgetConfig, setWidgetConfig] = useState(initialData);
  const [mapCoordinates, setMapCoordinates] = useState(initialMapCoordinates);
  const [enquiryFormNextLocation, setEnquiryFormNextLocation] = useState(null);
  const [dynamicContent, setDynamicContent] = useState({});
  const [externalEnquiryFormStep, setExternalEnquiryFormStep] = useState(0);

  const baseElement = `getheli-${config.app_type === TYPE_WIDGET ? "widget" : "iframe"}-base-element`;

  const memoizedGlobalState = useMemo(
    () => ({
      authData,
      setAuthData,
      globalState,
      setGlobalState,
      widgetConfig,
      setWidgetConfig,
      dynamicContent,
      mapCoordinates,
      setMapCoordinates,
      enquiryFormNextLocation,
      setEnquiryFormNextLocation,
      externalEnquiryFormStep,
      setExternalEnquiryFormStep,
      baseElement,
    }),
    [
      authData,
      globalState,
      widgetConfig,
      dynamicContent,
      mapCoordinates,
      enquiryFormNextLocation,
      externalEnquiryFormStep,
      baseElement,
    ],
  );

  useEffect(() => {
    setAuthData(config);
    setDynamicContent(config.content || {});
    setWidgetConfig(config);
  }, [config]);

  useEffect(() => {
    const root = document.querySelector("#" + baseElement);
    // SET ROOT STYLE
    if (config.appearance) {
      Object.entries(config.appearance).forEach(([propriety, value]) => root.style.setProperty(propriety, value));
    }
  }, [config]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <WidgetConfigContext.Provider value={memoizedGlobalState}>{children}</WidgetConfigContext.Provider>
  );
}

WidgetConfigProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  config: PropTypes.object,
  baseElement: PropTypes.string,
};

// custom hook
export const useWidgetConfig = () => useContext(WidgetConfigContext);
