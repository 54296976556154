import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./ModalEnquiryForm.scss";
import Modal from "react-modal";
import LayoutComplexWidget from "../../layouts/LayoutComplexWidget";
import cs from "classnames";
import { useContainerQuery } from "react-container-query";
import { queryBreakpoints } from "../../../constants/uiConfig";
import { useWidgetConfig } from "../../../context/widgetConfigContext";

const customStyles = {
  content: {
    background: "#fff",
    zIndex: 1001,
    maxWidth: "1200px",
    width: "90%",
    maxHeight: "90%",
    borderRadius: "4px",
    overflowY: "auto",
    overflowX: "hidden",
    position: "initial",
    border: "none",
    padding: "0",
  },
  overlay: {
    background: "rgba(0,0,0,0.74)",
    zIndex: 199998,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

function ModalEnquiryForm(props) {
  const { isModalOpen, closeModal } = props;
  const [params, containerRef] = useContainerQuery(queryBreakpoints);
  const { setGlobalState, widgetConfig } = useWidgetConfig();

  const modalRef = React.useRef(null);

  React.useEffect(() => {
    const modalRoot = modalRef.current?.node;
    if (!modalRoot) return;
    // SET ROOT STYLE
    if (widgetConfig.appearance) {
      Object.entries(widgetConfig.appearance).forEach(([propriety, value]) =>
        modalRoot?.style.setProperty(propriety, value),
      );
    }
  }, [modalRef]);

  useEffect(() => {
    setGlobalState((prevState) => ({ ...prevState, containerQueryParams: params }));
  }, [params, setGlobalState]);

  // prevent page scroll
  useEffect(() => {
    if (isModalOpen) document.body.style.overflow = "hidden";
    else if (!isModalOpen) document.body.style.overflow = "initial";
    return () => {
      document.body.style.overflow = "initial";
    };
  }, [isModalOpen]);
  return (
    <div className="gh-widget-modal-enquiry-form-wrapper">
      <Modal
        ariaHideApp={false}
        style={customStyles}
        isOpen={isModalOpen}
        contentLabel="onRequestClose Example"
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        portalClassName="gh-widget-app gh-widget-modal-root-element"
        ref={modalRef}
      >
        <div
          className={cs(
            "gh-widget-modal-enquiry-form-background",
            "gh-widget-app-cq",
            "gh-widget-app-type-modal",
            params,
          )}
          ref={containerRef}
        >
          <div className="gh-widget-app-blur">
            <LayoutComplexWidget />
          </div>
        </div>
      </Modal>
    </div>
  );
}

ModalEnquiryForm.propTypes = { isModalOpen: PropTypes.bool, closeModal: PropTypes.func };
ModalEnquiryForm.defaultProps = {};
export default ModalEnquiryForm;
