export const startAgainLabel = "Start Again";
export const backLabel = "Back";
export const estimatedPrice = "Estimated Price";
export const descriptionTitle = "What You'll Get";
export const getEstimates = "Get Estimate";
export const enquiryFurther = "Enquire";
export const selectButton = "Select";
export const estimatedFlightDuration = "Estimated Flight Duration";
export const maximumPassengers = "Maximum Passengers";
export const thankYou = "Thank You!";
export const optionsPriceDependsOneWay =
  "Based on historical quoting data. Prices vary depending on aircraft location, landing fees, demand, and other factors.";
export const optionsPriceDependsReturn =
  "Based on historical quoting data. Prices are for both ways and can vary depending on aircraft location, landing fees, demand, and other factors.";
export const specialCommentsPlaceholder = "Special comments and additional information";
export const additionalInfoLabel = "Additional Information";
export const departureTimeLabel = "Time";
export const returnTimeLabel = "Time";
export const submitLabel = "Submit";
export const datesLabel = "Dates:";
export const pickSeason = "Peak Season:";
export const venueLabel = "Venue:";
export const eventTypeLabel = "Event Type:";
export const eventDescription = "Event Description";
export const landingInformation = "Landing information";
export const helicoptersTitle = "Helicopters title";
export const returnLabel = "Return";
export const oneWayLabel = "One-way";
export const onDemandTitle = "Enquire now";
export const onDemandDescription =
  "For a business trip or for pleasure, get a price estimate by filling in the form with your journey's details as well as your contact information.";
export const onDemandMapLegendTitle = "destinations with helicopter landing facilities";

/*===========GPDR===========*/
export const gdprSameCompaniesName =
  "I consent to Air Charter Service using my information to contact me by telephone and email about my enquiry and / or related products and services.";
export const gdprDifferentCompaniesName = (from_company_name, to_company_name) =>
  `Please note that ${from_company_name} is collecting your data through a technology powered by ${to_company_name}. By submitting this form, you accept that ${to_company_name} might contact you about your enquiry.`;
export const gdprOnGetheli =
  "*By submitting this form, you consent to Get Heli using your information to contact you by telephone and email about your enquiry and/or related products and services. This information is stored in line with the Get Heli ";

/*===========Tooltips===========*/
export const tooltipFeatured = "Featured Event";
export const tooltipFeaturedDestination = "Featured Destination";
