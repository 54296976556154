import googleApi from "../services/google/googleApi";

export default async function secureLocationLatLng(location) {
  if (location.source === "google" && (!location.latitude || !location.longitude)) {
    try {
      const latLng = await googleApi.getCoordinatesByPlaceId(location.place_id);
      return Promise.resolve({ ...location, ...latLng });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return Promise.resolve(location);
}
