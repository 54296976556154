/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useContext, useEffect, useState } from "react";
import MarkerInfoContext from "./MarkerInfoContext";
import { ReactComponent as ArrowRight } from "../../../../assets/svg/mapArrowRight.svg";
import { generateChosenSiteText, renderTitleForPad } from "../Map.utils";

function GoogleMarker() {
  const {
    pin,
    pin: { name, handleAssignButton, direction },
    isSelected,
    updateChosenSitesByRole,
    view_link,
  } = useContext(MarkerInfoContext);
  const [showInfo, setShowInfo] = useState(false);
  const [chosenSiteText, setChosenSiteText] = useState();

  useEffect(() => {
    setChosenSiteText(pin.chosenSiteText || generateChosenSiteText(direction, updateChosenSitesByRole));
  }, [direction]);

  return (
    <div
      className="legInfoWindow selected google"
      id="id-info"
    >
      <div className="content">
        <p className="top-subtitle">pin address</p>
        <h4 className="top-head">{renderTitleForPad(view_link, name)}</h4>
        {isSelected && direction ? (
          <div className="legInfoWindowActionLegs">
            <h4 className="bottom-head">{chosenSiteText}</h4>
          </div>
        ) : (
          <div className="legInfoWindowActionLegs">
            {showInfo ? (
              <div>
                <a
                  role="button"
                  onClick={() => handleAssignButton("from")}
                  className="gh-widget-app-map-card-from-btn"
                >
                  FROM
                </a>
                <span style={{ margin: "0 15px", fontWeight: 600, fontSize: 14 }}>or</span>
                <a
                  role="button"
                  onClick={() => handleAssignButton("to")}
                  className="gh-widget-app-map-card-to-btn"
                >
                  TO
                </a>
              </div>
            ) : (
              <a onClick={() => setShowInfo(true)}>
                <h4 className="bottom-head secondary">
                  Use this landing site <ArrowRight />
                </h4>
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(GoogleMarker);
