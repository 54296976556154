import React from "react";
import PropTypes from "prop-types";
import "./Button.scss";
import Loader from "../Loader";

function Button(props) {
  const { onClick, children, variant, disabled, isLoading, color, className, style, actionType, show, ...rest } = props;
  if (!show) return "";

  if (actionType === "button")
    return (
      <button
        disabled={disabled || isLoading}
        onClick={onClick}
        className={`gh-widget-main-button-component gh-widget-main-button-component-${color} gh-widget-main-button-component-${variant} ${className} ${
          disabled || isLoading ? "disabled" : ""
        }`}
        style={style}
        {...rest}
      >
        {children}{" "}
        <Loader
          loading={isLoading}
          className="gh-widget-button-loader"
        />
      </button>
    );

  if (actionType === "link")
    return (
      <a
        disabled={disabled || isLoading}
        className={`gh-widget-main-button-component gh-widget-main-button-component-${color} gh-widget-main-button-component-${variant} ${className} ${
          disabled || isLoading ? "disabled" : ""
        }`}
        style={style}
        {...rest}
      >
        {children}{" "}
        <Loader
          loading={isLoading}
          className="gh-widget-button-loader"
        />
      </a>
    );
}

Button.propTypes = {
  variant: PropTypes.oneOf(["contained", "text", "outlined"]),
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "primaryBorder", "secondary", "success", "danger", "warning", "light", "dark"]),
  className: PropTypes.string,
  style: PropTypes.object,
  show: PropTypes.bool,
  actionType: PropTypes.oneOf(["button", "link"]),
};
Button.defaultProps = {
  variant: "contained",
  disabled: false,
  color: "success",
  className: "",
  style: {},
  onClick: () => {},
  isLoading: false,
  actionType: "button",
  show: true,
};
export default Button;
