import create from "zustand";
import { initialMapCoordinates } from "../constants/mapConfig";

const useMapStore = create((set) => ({
  enquiryFormNextLocation: null,
  setEnquiryFormNextLocation: (enquiryFormNextLocation) => set({ enquiryFormNextLocation }),
  mapCoordinates: initialMapCoordinates,
  setMapCoordinates: (mapCoordinates) => set({ mapCoordinates }),
}));

export default useMapStore;
