export const isObject = (value) => Object.prototype.toString.call(value) === "[object Object]";

export const isObjectEmpty = (obj) => {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
};

const deepObjectCompareIsObject = (object) =>
  object && Object.keys(object).length === 0 && Object.getPrototypeOf(object) === Object.prototype;

export function deepObjectCompare(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = deepObjectCompareIsObject(val1) && deepObjectCompareIsObject(val2);
    if ((areObjects && !deepObjectCompare(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
}
