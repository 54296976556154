import React, { memo } from "react";
import PropTypes from "prop-types";
import "./InputNumberIncrement.scss";

import { ReactComponent as Decrement } from "../../../assets/svg/minus.svg";
import { ReactComponent as Increment } from "../../../assets/svg/plus.svg";

function InputNumberIncrement(props) {
  const { className, label, max, min, step, onChange, value } = props;

  const increment = () => {
    if (value + step > max) return;
    onChange(value + step);
  };
  const decrement = () => {
    if (value - step < min) return;
    onChange(value - step);
  };

  return (
    <div className={`${className} gh-widget-number-increment-wrapper`}>
      <span className="gh-widget-label">{label || ""}</span>
      <div className="gh-widget-number-increment-buttons">
        <button
          type="button"
          className="gh-widget-number-increment-btn gh-widget-number-increment-btn-decrement"
          onClick={decrement}
        >
          <Decrement className="gh-widget-number-increment-svg" />
        </button>
        <input
          type="number"
          className="gh-widget-number-increment-readonly-input"
          value={value}
          readOnly
        />
        <button
          type="button"
          className="gh-widget-number-increment-btn gh-widget-number-increment-btn-increment"
          onClick={increment}
        >
          <Increment className="gh-widget-number-increment-svg" />
        </button>
      </div>
    </div>
  );
}
InputNumberIncrement.propTypes = {
  className: PropTypes.string,
  step: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
};
InputNumberIncrement.defaultProps = {
  className: "",
  step: 1,
  label: "",
  value: 0,
  onChange: () => {},
  min: 0,
  max: 100,
};
export default memo(InputNumberIncrement);
