export const LondonLocation = { lat: 51.509865, lng: -0.118092 };

export const mapStyles = [
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.attraction",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

export const config = {
  maxAllowedMapeHeight: 800,
};

export function parseCoordinates(coordinates) {
  if (!coordinates || typeof coordinates !== "string" || coordinates.length < 1) {
    // throw Error('Invalid leg coordinates given ' + coordinates);
    return false;
  }
  const chunks = coordinates.split("|");
  return {
    lat: parseFloat(chunks[0]),
    lng: parseFloat(chunks[1]),
  };
}

export function generateCurvedLine(google, curvature) {
  const getMarker = (position) =>
    new google.maps.Marker({
      position,
    });

  return function (dir, projection, mapZoom) {
    if (dir && projection && mapZoom) {
      if (!dir.from || !dir.to) return;

      const positionFrom = getMarker(dir.from).getPosition();
      const positionTo = getMarker(dir.to).getPosition();

      const positionToFirstPoint = projection.fromLatLngToPoint(positionFrom);
      const positionToSecondPoint = projection.fromLatLngToPoint(positionTo);

      const e = new google.maps.Point(
        positionToSecondPoint.x - positionToFirstPoint.x,
        positionToSecondPoint.y - positionToFirstPoint.y,
      );
      const m = new google.maps.Point(e.x / 2, e.y / 2);
      const o = new google.maps.Point(e.y, -e.x);
      const c = new google.maps.Point(m.x + curvature * o.x, m.y + curvature * o.y);

      const path_defined = `M 0,0 ` + `q ${c.x},${c.y} ${e.x},${e.y}`;

      const curvedLineMiddlePoint = new google.maps.Point(
        c.x + positionToFirstPoint.x - curvature * o.x * 0.5,
        c.y + positionToFirstPoint.y - curvature * o.y * 0.5,
      );

      const aPt = projection.fromPointToLatLng(curvedLineMiddlePoint);
      const lineHeading = window.google.maps.geometry.spherical.computeHeading(positionFrom, positionTo); // direction to head of the curved line
      const relativeScale = 1 / 2 ** -mapZoom;

      const drawnSymbol = {
        path: path_defined,
        scale: relativeScale,
        strokeWeight: 3,
        strokeColor: "#242F42",
        strokeOpacity: 1.0,
        fillColor: "none",
      };

      return {
        position: positionFrom,
        clickable: false,
        icon: drawnSymbol,
        zIndex: -1,
        midLine: aPt,
        rotation: lineHeading,
      };
    }
  };
}

export const initialMapCoordinates = {
  key: "map-locations",
  pad: {},
};
