import PropTypes from "prop-types";
import cs from "classnames";
import "./Title.scss";

const Title = ({ content, className, type }) => {
  return <h4 className={cs(className, `${type}Title`)}>{content}</h4>;
};

Title.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(["standard", "medium", "main", "extra-small", "small"]),
};
Title.defaultProps = { className: "", type: "standard" };
export default Title;
