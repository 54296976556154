/*= ==========Number increment=========== */
export const DECREMENT = "decrement";
export const INCREMENT = "increment";
/*= ==========Form statuses=========== */
export const FAILED = "failed";
export const SUCCESS = "success";
export const DISABLED = "disabled";
/*= ==========Api messages=========== */
export const defaultErrorMessage = "Something went wrong";
export const INCLUDES = "INCLUDES";
export const NO_INCLUDES = "NO-INCLUDES";
export const EXACT = "EXACT";

/*===========Form type===========*/
export const ON_DEMAND_FROM = "on_demand";
export const EVENT_FROM = "event";

/*===========Form type===========*/
export const ONE_WAY = "one";
export const ROUND_TRIP = "round";
