import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "../../../hooks/usePagination";
import "./Pagination.scss";
import PropTypes from "prop-types";
import Text from "../Text";
import { ReactComponent as ArrowSVG } from "../../../assets/svg/arrowRightPagination.svg";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    prefix,
    paginationRef,
    ...rest
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
      ref={paginationRef}
      {...rest}
    >
      <Text className="gh-widget-pagination-prefix">{prefix}</Text>
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className="gh-widget-arrow left">
          <ArrowSVG />
        </div>
      </li>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            className={classnames("pagination-item", {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
            key={pageNumber}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <div className="gh-widget-arrow right">
          <ArrowSVG />
        </div>
      </li>
    </ul>
  );
};
Pagination.propTypes = {
  onPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  className: PropTypes.string,
  prefix: PropTypes.string,
  paginationRef: PropTypes.object,
};
export default Pagination;
