import React from "react";
import PropTypes from "prop-types";
import "./TabPaneMain.scss";

const TabPaneMain = ({ children }) => {
  return <div className="gh-widget-main-tab-pane">{children}</div>;
};

TabPaneMain.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]).isRequired,
};
export default TabPaneMain;
