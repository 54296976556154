// import { ReactComponent as YellowMarker } from "../assets/svg/yellowMarker.svg";
// import { ReactComponent as GreenMarker } from "../assets/svg/greenMarker.svg";
// import { ReactComponent as OvalMarker } from "../assets/svg/oval.svg";
//
// export const IconByPlaceType = ({ place }) => {
//   switch (place) {
//     case "gh_event":
//       return <YellowMarker />;
//     case "hellipaddy":
//       return <GreenMarker />;
//     case "google":
//       return <OvalMarker />;
//   }
// };

/**
 * Returns the element height including margins
 * @param element - element
 * @returns {number}
 */
export function getOuterHeight(element) {
  const height = element.offsetHeight,
    style = window.getComputedStyle(element);

  return ["top", "bottom"]
    .map((side) => parseInt(style[`margin-${side}`]))
    .reduce((total, side) => total + side, height);
}
