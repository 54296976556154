import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as WarningIcon } from "../../../assets/svg/warningIconCircle.svg";
import "./WarningMessage.scss";

const WarningMessage = (props) => {
  const { message } = props;

  if (!message) return "";

  return (
    <div className="warning-row">
      <WarningIcon className="warning-row__icon" />
      <div
        className="warning-row__message"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </div>
  );
};

WarningMessage.propTypes = {
  message: PropTypes.string,
};

export default WarningMessage;
