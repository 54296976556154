import React from "react";
import Title from "../../UI/Title";
import MainTabNavigation from "../../Views/MainTabNavigation";
import useHasFeature from "../../../hooks/useHasFeature";
import { POWERED_BY_GETHELI } from "../../../constants/features";
import cs from "classnames";

import "./LayoutComplexWidget.scss";

const LayoutComplexWidget = () => {
  const { checkFeature } = useHasFeature();
  const hasFeaturePoweredByGetheli = checkFeature(POWERED_BY_GETHELI);
  // const isGetheliPlatform = new URLSearchParams(window.location?.search).get("p") === "gh";
  const isGetheliPlatform = window.location.href.indexOf("getheli") > -1;

  return (
    <div
      className={cs("gh-widget-main-wrapper", {
        "gh-widget-main-wrapper-small-paddings": !hasFeaturePoweredByGetheli,
        "gh-getheli-platform": isGetheliPlatform,
      })}
      id="gh-widget-main-wrapper"
    >
      {!isGetheliPlatform && (
        <Title
          type="main"
          content="CHOOSE YOUR HELICOPTER DESTINATION"
          className="gh-widget-main-title"
        />
      )}
      <MainTabNavigation />
    </div>
  );
};
LayoutComplexWidget.propTypes = {};
export default LayoutComplexWidget;
