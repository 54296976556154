import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import { ReactComponent as LeftArrowSVG } from "../../../assets/svg/arrow-left-dark.svg";
import "./ButtonBack.scss";
import cs from "classnames";

const ButtonBack = (props) => {
  const { onClick, label, className } = props;
  return (
    <Button
      onClick={onClick}
      color="dark"
      variant="text"
      className={cs("gh-widget-back-button", className)}
    >
      {label}
      <LeftArrowSVG />
    </Button>
  );
};

ButtonBack.propTypes = { label: PropTypes.string, className: PropTypes.string, onClick: PropTypes.func };
ButtonBack.defaultProps = {
  label: "",
  className: "",
  onClick: () => {},
};
export default ButtonBack;
