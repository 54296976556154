import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import moment from "moment";
// import { actions } from "react-redux-form";
import { utcTime } from "../../../utils/timeUtils";

import "./DateTimeComponent.scss";

function DateTimeComponent(props) {
  const {
    dateFormat,
    className,
    defaultValue,
    disabled,
    isValidDate,
    isValidDateBefore,
    name,
    readOnly,
    onChange,
    placeholder,
    required,
    showClearIcon,
    timeConstraints,
    timeFormat,
    value,
    attrProps,
    closeOnSelect,
    time,
    requestCalendarFields,
    icon,
    iconLabel,
    calendarLabel,
    onClear,
    isValidBeforeSpecificDate,
    isValidAfterSpecificDate,
    pickOnly,
  } = props;
  const [date, setDate] = useState(undefined);

  const input = useRef();

  useEffect(() => {
    const formattedDate =
      dateFormat && defaultValue
        ? Datetime.moment(defaultValue).subtract(1, "day")
        : Datetime.moment().subtract(1, "day");

    setDate(formattedDate);
  }, [defaultValue, dateFormat]);

  const isValidDateHandler = (current) => {
    if (isValidBeforeSpecificDate) {
      return current.isBefore(moment(isValidBeforeSpecificDate)) && current.isAfter(date);
    }
    if (isValidAfterSpecificDate) {
      return current.isAfter(moment(isValidAfterSpecificDate));
    }
    if (isValidDate) {
      return current.isAfter(date);
    }

    if (isValidDateBefore) {
      return current.isBefore(date);
    }

    return true;
  };

  const onBlurHandler = (val) => {
    if (val) {
      // onCloseAction ? onCloseAction(name, value) : value;
    }
  };

  const renderViewHandler = (_, renderDefault) => {
    return (
      <div className="wrapper">
        {renderDefault()}
        {!!requestCalendarFields && requestCalendarFields}
      </div>
    );
  };

  const onClearHandler = () => {
    // dispatch(actions.resetValidity(name));
    // dispatch(actions.setTouched(name));
    // dispatch(actions.change(name, ""));
  };

  /* eslint-disable */
  return (
    <div
      {...attrProps}
      onClick={() => !!timeFormat && !value && onChange(moment("12:00", "HH:mm"))}
      role="button"
      tabIndex={0}
    >
      {time && (
        <span
          aria-hidden="true"
          onClick={() => !!input.current && input.current._onInputClick()}
          className="gh-widget-utc"
        >
          {utcTime()}
        </span>
      )}
      <Datetime
        initialValue={timeFormat ? "12:00" : defaultValue}
        ref={input}
        inputProps={{
          name,
          required,
          placeholder,
          className: className || "",
          readOnly,
          disabled,
        }}
        value={value || ""}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        onChange={onChange}
        timeConstraints={timeConstraints}
        closeOnSelect={closeOnSelect}
        isValidDate={isValidDateHandler}
        onClose={onBlurHandler}
        onBlur={onBlurHandler}
        renderView={renderViewHandler}
        renderInput={(props) => (
          <input
            {...props}
            autoComplete="off"
            onChange={pickOnly ? () => {} : props.onChange}
            onClick={() => document.querySelectorAll(".ghWidgetUtcClass").forEach((el) => el.blur())}
            value={value ? props.value : ""}
            id={"ghWidgetUtc" + name}
            className={props.className + " ghWidgetUtcClass"}
          />
        )}
      />
      {icon ? (
        <img
          src={icon}
          alt="Calendar"
          className="gh-widget-enquire-calendar-icon"
        />
      ) : (
        <span className="gh-widget-calendar-icon" />
      )}

      {!!iconLabel && <span className="gh-widget-icon-label">{iconLabel}</span>}

      {!!calendarLabel && calendarLabel}

      {showClearIcon && (
        <span
          onClick={() => {
            if (onClear && typeof onClear === "function") {
              onClear(name);
            } else onClearHandler();
          }}
          className="gh-widget-close-icon"
          aria-hidden="true"
        />
      )}
    </div>
  );
}

/* eslint-enable */

DateTimeComponent.propTypes = {
  attrProps: PropTypes.object,
  className: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  dateFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  dispatch: PropTypes.func,
  isValidDate: PropTypes.bool,
  isValidDateBefore: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showClearIcon: PropTypes.bool,
  timeConstraints: PropTypes.object,
  timeFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  time: PropTypes.bool,
  readOnly: PropTypes.bool,
  requestCalendarFields: PropTypes.object,
  onCloseAction: PropTypes.func,
  icon: PropTypes.string,
  iconLabel: PropTypes.string,
  calendarLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pickOnly: PropTypes.bool,
  isValidBeforeSpecificDate: PropTypes.object,
  isValidAfterSpecificDate: PropTypes.object,
};

DateTimeComponent.defaultProps = {
  attrProps: {},
  defaultValue: undefined,
  placeholder: "Pick a date",
  className: "",
  dateFormat: "DD MMMM, YYYY",
  timeFormat: false,
  required: false,
  isValidDate: false,
  value: undefined,
  timeConstraints: {},
  showClearIcon: true,
  disabled: false,
  closeOnSelect: true,
  pickOnly: false,
};

export default DateTimeComponent;
