import useWidgetConfigStore from "../../../../../store/useWidgetConfigStore";
import { gdprDifferentCompaniesName, gdprOnGetheli, gdprSameCompaniesName } from "../../../../../constants/content";

const privacyPolicyLink = "https://www.iubenda.com/privacy-policy/89754695";

const useGdprText = () => {
  const gdpr = useWidgetConfigStore((state) => state.widgetConfig.content.general_data_protection_regulation);
  const from_company_name = gdpr?.from_company_name;
  const to_company_name = gdpr?.to_company_name;

  const isGdprCompaniesTheSame = from_company_name === to_company_name;
  const isGetheliPlatform = new URLSearchParams(window.location?.search).get("p") === "gh";

  let GdprText = () => <></>;

  if (isGetheliPlatform) {
    // eslint-disable-next-line react/display-name
    GdprText = () => (
      <>
        {gdprOnGetheli}
        <a
          href={privacyPolicyLink}
          target="_blank"
          className="font-weight-500 gh-link-gray uk-padding-remove"
          rel="noreferrer"
          style={{ fontWeight: 600, color: "#868e9e", padding: 0, textDecoration: "none" }}
        >
          privacy policy.
        </a>
      </>
    );
  } else if (isGdprCompaniesTheSame) {
    // eslint-disable-next-line react/display-name
    GdprText = () => <>{gdprSameCompaniesName}</>;
  } else {
    // eslint-disable-next-line react/display-name
    GdprText = () => <>{gdprDifferentCompaniesName(from_company_name, to_company_name)}</>;
  }

  if ((!from_company_name || !to_company_name) && !isGetheliPlatform) {
    // eslint-disable-next-line react/display-name
    GdprText = () => <></>;
  }

  return { GdprText };
};

export default useGdprText;
