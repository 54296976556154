import { transformDateTimeToISO } from "../../../../utils/timeUtils";
import { ROUND_TRIP } from "../../../../constants/otherConstants";

const getEnquiryFormDatesForApi = ({ formLocations, typeOfTrip, formType, currentLogId, eventID }, getValues) => {
  let return_time_formatted, departure_time_formatted;

  const values = getValues();
  const { departure_at, return_at, return_date, departure_date, luggage } = values;
  const { number_children, number_adults } = values.passengers;

  departure_time_formatted = transformDateTimeToISO(departure_date, departure_at);
  if (typeOfTrip === ROUND_TRIP) {
    return_time_formatted = transformDateTimeToISO(return_date, return_at);
  }

  const phone = { ...values.phone };
  phone.value = phone.inpValue;

  const pax = number_children + number_adults;
  const data = {
    ...values,
    ...formLocations,
    departure_time: departure_time_formatted,
    return_time: return_time_formatted,
    phone,
    pax: { pax, children: number_children, adults: number_adults, luggage },
    event_type: formType,
    log_id: currentLogId,
  };

  if (eventID) {
    data[formTypes[formType] + "_id"] = eventID;
  }

  delete data.luggage;
  delete data.departure_date;
  delete data.return_date;
  delete data.passengers;
  // delete data.from_location.location;
  // delete data.to_location.location;
  delete data.phone.inpValue;
  return data;
};
export default getEnquiryFormDatesForApi;

const formTypes = {
  destinations: "destination",
  event: "event",
};
