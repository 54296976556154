import React, { useEffect, useRef, useState } from "react";
import "./HelicoptersOptionsItem.scss";
import PropTypes from "prop-types";
import { getTextWidthOld, removeFractionalPart } from "../../../utils/stringUtils";
import { formattedTime } from "../../../utils/timeUtils";
import { ReactComponent as InfoIconSvg } from "../../../assets/svg/info.svg";
import {
  descriptionTitle,
  estimatedFlightDuration,
  estimatedPrice,
  maximumPassengers,
  selectButton,
} from "../../../constants/content";
import Button from "../../UI/Button";
import ImageSlider from "../ImageSlider";
import WarningMessage from "../../UI/WarningMessage";

const calculateTextWidth = getTextWidthOld();
const renderModel = (model, hideParenthesis) =>
  hideParenthesis ? model.replaceAll("(", "").replaceAll(")", "") : model;

function HelicoptersOptionsItem(props) {
  const {
    estimated_price,
    helicopterId,
    model,
    images,
    max_passengers,
    duration,
    children,
    hideParenthesis,
    description,
    id,
    handleGoToInfo,
    warningMessages,
    warningMessage,
  } = props;

  const { max: maxP, min: minP, average: averageP } = estimated_price;

  const [averagePos, setAveragePos] = useState(null);

  const itemText = useRef();
  const rangeLine = useRef();

  const getAveragePos = (average, total, minW, maxW, averageW) => {
    if (rangeLine.current) {
      const lineWidth = rangeLine.current.offsetWidth;

      const localAveragePos = (average / total) * lineWidth - 15;
      const calcMin = minW + 20;
      const calcMax = lineWidth - maxW - averageW + 10;

      if (!Number.isNaN(localAveragePos)) {
        if (localAveragePos < calcMax && localAveragePos > calcMin) return localAveragePos;
        if (localAveragePos < calcMin) {
          if (calcMin < 100) return calcMin + 4;
          return calcMin;
        }
        if (localAveragePos > calcMax) return calcMax;
      }
    }
  };
  const { minW, maxW, averageW } = {
    minW: calculateTextWidth(`£${removeFractionalPart(minP)}`, itemText.current),
    maxW: calculateTextWidth(`£${removeFractionalPart(maxP)}`, itemText.current),
    averageW: Math.max(
      calculateTextWidth("Average", itemText.current),
      calculateTextWidth(`£${removeFractionalPart(averageP)}`, itemText.current),
    ),
  };

  useEffect(() => {
    setAveragePos(
      getAveragePos(Number(averageP.replace(/,/g, "")), Number(maxP.replace(/,/g, "")), minW, maxW, averageW),
    );
  }, [rangeLine]);

  return (
    <div
      key={helicopterId}
      className="gh-widget-heli-card"
    >
      <div className="gh-widget-heli-card-image">
        <h3 className="gh-widget-heli-card-title gh-widget-margin-bottom-10">
          {renderModel(model, hideParenthesis)}
          <div
            data-tip
            data-for={`heli-title-${helicopterId}`}
            data-iscapture="true"
            className="gh-widget-tooltip-trigget"
          >
            <InfoIconSvg />
          </div>
        </h3>

        <div className="gh-event-helicopter-carousel">
          <ImageSlider
            imagesUrl={images.map((el) => el.file_url)}
            options={{ loop: true }}
            autoPlay={true}
          />
        </div>
        <div className="gh-widget-heli-item-label description-title">
          <div>{descriptionTitle}</div>
        </div>
        <div
          className="gh-event-helicopter-desc"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className="gh-widget-heli-card-content">
        <div className="gh-widget-d-flex gh-widget-flex-column">
          <div className="gh-widget-heli-card-subtitle">
            <div className="gh-widget-heli-item-label">
              <div>{estimatedPrice}</div>
            </div>
            <div
              data-tip
              data-for={`heli-description-${helicopterId}`}
              data-iscapture="true"
              className="gh-widget-tooltip-trigget"
            >
              <InfoIconSvg />
            </div>
          </div>

          <div className="gh-widget-heli-item-range">
            <div className="gh-widget-heli-item-range_item from">
              <span className="gh-widget-heli-item-label">From</span>
              <span
                className="gh-widget-heli-item-text"
                ref={itemText}
              >
                {`£${removeFractionalPart(minP)}`}
              </span>
            </div>
            <div
              className="gh-widget-heli-item-range_item average"
              style={{
                left: `${averagePos - 28}px`,
              }}
            >
              <span className="gh-widget-heli-item-label">Average</span>
              <span className="gh-widget-heli-item-text">{`£${removeFractionalPart(averageP)}`}</span>
            </div>
            <div className="gh-widget-heli-item-range_item to">
              <span className="gh-widget-heli-item-label">To</span>
              <span className="gh-widget-heli-item-text">{`£${removeFractionalPart(maxP)}`}</span>
            </div>
            <div
              className="gh-widget-heli-item-range_line"
              ref={rangeLine}
            >
              <div
                className="gh-widget-heli-item-range_marker"
                style={{
                  left: `${averagePos - 30}px`,
                  width: averageW,
                }}
              />
            </div>

            <WarningMessage message={warningMessages?.static_content} />
            <WarningMessage message={warningMessages?.from_location} />
            <WarningMessage message={warningMessages?.to_location} />
            <WarningMessage message={warningMessage} />
          </div>
          <div className="gh-widget-heli-info-wrapper gh-widget-margin-top-10">
            <div className="gh-widget-heli-info-item">
              <div className="gh-widget-heli-item-label">{estimatedFlightDuration}</div>
              <div className="gh-widget-heli-item-text">
                {duration < 15 ? "Less than 15 minutes" : formattedTime(duration)}
              </div>
            </div>
            <div className="gh-widget-heli-info-item">
              <div className="gh-widget-heli-item-label">{maximumPassengers}</div>
              <div className="gh-widget-heli-item-text">{max_passengers}</div>
            </div>
          </div>
        </div>
        {children}
        <Button
          type="button"
          onClick={() => handleGoToInfo(id)}
        >
          {selectButton}
        </Button>
      </div>
    </div>
  );
}

HelicoptersOptionsItem.propTypes = {
  estimated_price: PropTypes.object.isRequired,
  helicopterId: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  max_passengers: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  children: PropTypes.element,
  hideParenthesis: PropTypes.bool,
  id: PropTypes.number,
  handleGoToInfo: PropTypes.func,
  description: PropTypes.string,
  warningMessages: PropTypes.object,
  warningMessage: PropTypes.string,
};

HelicoptersOptionsItem.defaultProps = {
  children: null,
  hideParenthesis: false,
};

export default HelicoptersOptionsItem;
